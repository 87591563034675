//
//  Common
//  _____________________________________________

.block-search {
    margin-bottom: 0;
    position: relative;
    z-index: 99;

    .block {
        &-title {
            display: none;
        }
    }

    .block-content {
        margin-bottom: 0;
    }

    .label {
        @include lib-icon-font(
            $_icon-font-content     : $icon-search,
            $_icon-font-size        : 35px,
            $_icon-font-line-height : 33px,
            $_icon-font-color       : $minicart-icons-color,
            $_icon-font-color-hover : $minicart-icons-color-hover,
            $_icon-font-color-active: $minicart-icons-color-hover,
            $_icon-font-text-hide   : true,
            $_icon-font-display     : block
        );

        &:before {
            position: relative;
            bottom: 10px;
        }

        &.active {
            + .control {
                input {
                    position: static;
                }
            }
        }
    }

    .action.search {
        display: none;
    }

    .control {
        clear: both;
        margin: 0 (-$layout__width-xs-indent) -1px;
        padding: 0 $layout__width-xs-indent;
    }

    input {
        left: -300%;
        margin: 15px 0;
        background: $color-black;
        border: 0;
        border-bottom: 1px solid $color-yellow;
        color: $color-yellow;
        padding: 0;
        margin: 0;
        transition: all 0.5s ease-in-out;
        top: 10px;

    }

    .nested {
        display: none;
    }
}

.search-autocomplete {
    @extend .abs-add-box-sizing;
    display: none;
    margin-top: 0;
    overflow: hidden;
    position: absolute;
    z-index: 99;
    background: url(../images/bg-weather.jpg) $color-black;
    color: $color-yellow;
    padding: 1rem;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);

    ul {
        @include lib-list-reset-styles();

        li {
            border-top: $autocomplete-item__border;
            cursor: pointer;
            margin: 0;
            padding: $indent__xs $indent__xl $indent__xs $indent__s;
            position: relative;
            text-align: left;
            white-space: normal;

            &:not(:empty) {
                border-top: 0;
                border: $autocomplete__border;
                background: $autocomplete__background-color;
            }

            &:first-child {
                border-top: none;
            }

            &:hover,
            &.selected {
                background: $autocomplete-item__hover__color;
            }

            .amount {
                color: $autocomplete-item-amount__color;
                position: absolute;
                right: 7px;
                top: $indent__xs;
            }
        }
    }
}
@supports (gap: 1px) {
  .search-autocomplete {
    background: url('../images/bg-weather.webp') $color-black;
  }
}
.form.search.advanced {
    .fields.range {
        .field {
            &:first-child {
                position: relative;

                .control {
                    padding-right: 25px;

                    &:after {
                        content: ' \2013 ';
                        display: inline-block;
                        position: absolute;
                        right: 0;
                        text-align: center;
                        top: 6px;
                        width: 25px;
                    }
                }
            }

            &:last-child {
                position: relative;

                div.mage-error[generated] {
                    left: 0;
                    position: absolute;
                    top: 32px;
                }
            }

            &.with-addon {
                .control {
                    padding-right: 45px;
                }
            }
        }
    }

    .group.price {
        .addon {
            .addafter {
                background: none;
                border: 0;
                padding-top: 6px;
                position: absolute;
                right: 0;
                top: 0;
            }
        }
    }
}

.search.summary {
    margin-bottom: $indent__s;
}

//
//  Mobile
//  _____________________________________________



@include max-screen($screen__m - 1) {
    .block-search {
        .block-content {
            margin-bottom: 0;
            width: 30px;
        }
    }

    .logo {
        img {
            display: none !important;
        }
        .inner-logo{
          width: 85% !important;
        }
    }
}


@include max-screen($screen__s) {
    .block-search {
        margin-top: $indent__s;

        input {
            left: -300%;
            position: absolute;
            border: 0;
            top: 10px;
            transition-property: top, left;
            transition-duration: 0.5s, 0s;
            transition-delay: 0s, 0.5s;
        }
        .label.active + .control input{
          position: absolute;
          top: 38px;
          left:0;
          transition: top 0.7s ease 0s;
        }
        .control {
            max-height: 0;
        }

        &.open-search {

            input {
                max-height: 100px;
                border-bottom: 1px solid #c6a540;
                padding: 0 10px;
            }

            .control {
                max-height: 100px;
            }
        }
    }
    .search-autocomplete {
      margin-top: 40px;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {

    .navigation .level0 > .level-top{
        bottom: 4px;
        font-size: 12px;
    }
    .block-search {
        padding-left: 15px;
        position: relative;
        width: 30px;
        position: absolute;
        top: -38px;

        &.open-search {
            width: 250px;

            input {
                width: 250px;
                padding-right: 35px;
                padding-left: 15px;
            }

        }


        .label {
            @extend .abs-visually-hidden-desktop;
            float: right;
        }

        .control {
            border-top: 0;
            margin: 0;
            padding: 0 0 25px;
        }

        .nested {
            display: none;
            padding-top: 5px;
            position: absolute;
        }

        input {
            @include lib-input-placeholder() {
                color: $form-element-input-placeholder__color;
            }
            width: 0;
            position: static;
        }

        .action.search {
            display: inline-block;
            @include lib-button-icon(
                $_icon-font-content  : $icon-search,
                $_icon-font-text-hide: true,
                $_icon-font-color    : $header-icons-color
            );
            @include lib-button-reset();
            padding: $indent__xs 0;
            position: absolute;
            right: 10px;
            top: 0;
            z-index: 99;

            &:focus {
                &:before {
                    color: $color-gray20;
                }
            }
            &[disabled]{
              opacity: 1 !important;
            }
        }
    }
}
@include min-screen($screen__l) {
    .navigation .level0 > .level-top{
        bottom: unset;
        font-size: 14px;
    }

}
