.block.filter {
    margin-bottom: 0;

    .filter-title {
        margin-bottom: 0;
        cursor: pointer;
        height: 20px;
        line-height: 20px;
        z-index: 120;
        position: relative;

        strong {
            font-size: 15px;
        }
    }
    .filter-title:after {
      font-family: 'icons-blank-theme';
      content: '\e622';
      font-size: 25px;
      vertical-align: bottom;
      margin-left: -5px;
    }
}

.block-subtitle {
    display: inline-block;
    margin-bottom: $indent__s;
}

.filter-current {
    .item {
        margin-bottom: $indent__s;
    }

    .filter-label {
        display: block;
        font-weight: $font-weight__bold;

        &:after {
            content: ':';
        }
    }

    + .filter-actions {
        margin-bottom: $indent__l;
    }
}

.filter-options-content {
    .filter-count-label {
        @extend .abs-visually-hidden;
    }
}

.filter-options {
    margin: 0;

    .filter-options-title {
        @include lib-heading(h4);
        margin: 0 0 $indent__s;
        word-break: break-all;
    }

    .filter-options-content {
        margin: 0 0 0;

        .item {
            margin-bottom: 5px;
        }
    }

    .count {
        color: $text__color__muted;

        &:before {
            content: '(';
        }

        &:after {
            content: ')';
        }
    }
}

.items {
    @extend .abs-reset-list;
}

.filtered {
    .items {
        margin: 15px 0;
    }

    .item {
        position: relative;
        padding-left: 22px;
        margin-bottom: 6px;

        .label {
            font-weight: $font-weight__bold;
        }

        .action.remove {
            @extend .abs-remove-button-for-blocks;
            position: absolute;
            left: -6px;
            top: 0;
        }
    }

    & + .actions {
        margin-bottom: 35px;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .page-with-filter {
        .columns {
            .sidebar-main {
                order: 0;
            }
        }
    }
}
