.checkout-index-index #discount-form-gift div.actions-toolbar {
    display : block!important;
    width : 100%;
}
.checkout-index-index #discount-form-gift div.primary {
    float : right;
}
.checkout-index-index #discount-form-gift .payment-option-inner div.control {
    margin: 5px 0;
}
.product-add-form .product-options-wrapper .fieldset > .field.gift-card > label.label {
    display: block;
    width: auto;
    line-height: 20px;
}
.product-add-form .product-options-wrapper .fieldset > .field.gift-card{
  width: 100%;
}
.webkul-searchBy-form > td{
    width: 90%;
    margin-top: 8px;
    padding: 0;
}
