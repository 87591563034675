//
//  Category view
//  ---------------------------------------------

p.text-hide {
    text-indent: -100000px;
    line-height: 0;
    margin: 0;
}

h2.large {
    font-size: 40px;
    margin-top: 30px;
}

.product-lewis-leathers-canvas-bags-2 {
    .page-title {
        span {
            background-image: url(../images/Lewis-Leathers-Canvas-Bags-title.png);
            font-size: 97px;
            letter-spacing: 19px;
            text-indent: -10000px;
            display: block;
            background-size: 100%;
            height: 206px;
        }
    }
}

body.cms-home {

    .page-main {
        padding: 0;

        .columns {
            .column.main {
                padding: 0;

                p {
                    margin: 0;
                    line-height: 0;
                }
            }
        }
    }
}


body.account {
    .columns {
        text-align: left;
    }
}

.columns {



    figcaption {
        text-align: center;
    }


    figure {
        width: 100%;
    }


    &.span-6 {
        img,
        figure {
            margin: 0 auto;
        }
    }

    .row {
        img {

            &:hover {
                transform: scale(1);
            }
        }

        &.has-text {
            p {
                padding-right: 2.5rem;
                padding-left: 2.5rem;

                &:first-child {
                    padding-top: 8rem;
                    padding-bottom: 0.75rem;
                }
            }
        }
    }

}

.old-price,
.old.price {
    text-decoration: line-through;
}

.prices-tier {
    .price-container {
        .price-including-tax {
            + .price-excluding-tax {
                &:before {
                    content: '(' attr(data-label) ': ';
                }

                &:last-child:after {
                    content: ')';
                }
            }
        }

        .weee[data-label] {
            display: inline;

            .price {
                font-size: 11px;
            }

            &:before {
                content: ' +' attr(data-label) ': ';
            }
        }
    }
}

.actual-price {
    font-weight: $font-weight__bold;
}

.product.name a {
    @extend .abs-product-link;
}

.category {
    &-image {
        .image {
            display: block;
            height: auto;
            max-width: 100%;
        }
    }

    &-image,
    &-description {
        margin-bottom: $indent__base;
    }

}
.catalog-category-view .products.list.items .action.towishlist{
  content: url(../images/star-wishlist-catalog.svg);
  width: 24px;
}
.catalog-category-view .products.list.items .action.towishlist:hover{
  content: url(../images/star-wishlist.svg);
}
//
//  Product images general container
//  ---------------------------------------------

.product-image {
    &-container {
        display: inline-block;
        max-width: 100%;
    }

    &-wrapper {
        display: block;
        height: 0;
        overflow: hidden;
        position: relative;
        z-index: 1;
    }

    &-photo {
        bottom: 0;
        display: block;
        height: auto;
        left: 0;
        margin: auto;
        max-width: 100%;
        width: auto;
        position: absolute;
        right: 0;
        top: 0;
        max-height: 100%;
    }
}

//
//  Product view
//  ---------------------------------------------

.product.media {
    .product.photo .photo.image {
        @extend .abs-adaptive-images-centered;
    }

    .placeholder .photo.container {
        max-width: 100%;
    }

    .notice {
        color: $text__color__muted;
        font-size: $font-size__s;
        margin: $indent__s 0;
    }

    .product.thumbs {
        margin: $indent__base 0 $indent__l;
    }

    .items.thumbs {
        @include lib-list-inline();

        .active {
            display: block;
            line-height: 1;
        }
    }
}

.product.info.detailed {
    clear: both;

    .additional-attributes {
        width: auto;
        @include lib-table-resize(
            $_th-padding-left  : 0,
            $_th-padding-right : $indent__l,
            $_th-padding-bottom: $indent__s,
            $_td-padding-bottom: $indent__s
        );
    }
}

.row {
    @include lib-clearfix();
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.product-info-main {
    .page-title-wrapper {
        .page-title {
            margin-bottom: $indent__s;
            font-size: 24px;
        }
    }

    .stock {
        &.available,
        &.unavailable {
          //  display: none;
            font-weight: $font-weight__bold;
            margin-right: $indent__base;
            text-transform: uppercase;
            vertical-align: top;
        }
    }

    .product {
        &.attribute {
            &.sku {
                display: none;
                vertical-align: top;
                color: $text__color__muted;

                > .value {
                    display: inline-block;
                    vertical-align: top;
                    word-break: break-all;
                }

                .type {
                    margin-right: $indent__xs;
                }
            }

            &.overview {
                margin: $indent__base 0;

                p {
                    font-size: 15px;
                    font-weight: 900;
                    line-height: 18px;
                    font-family: times;
                }
            }
        }

        &.alert {
            margin: $indent__s 0;
        }
    }

    .price-box {
        margin-top: $indent__s;
    }

    .product-reviews-summary .reviews-actions {
        font-size: $font-size__base;
    }
}

.product-options-wrapper {
    margin-bottom: 10px;
    .fieldset-product-options-inner {
        .legend {
            font-weight: $font-weight__bold;
            margin: 0 0 $indent__xs;
            font-size: 14px;
            border: none;
            display: inline-block;
            padding: 0;
        }

        &.required,
        &._required {
            .legend {
                &:after {
                    content: '*';
                    @include lib-typography(
                        $_font-size  : $form-field-label-asterisk__font-size,
                        $_color      : $form-field-label-asterisk__color,
                        $_font-family: $form-field-label-asterisk__font-family,
                        $_font-weight: $form-field-label-asterisk__font-weight,
                        $_line-height: $form-field-label-asterisk__line-height,
                        $_font-style : $form-field-label-asterisk__font-style
                    );
                    margin: $form-field-label-asterisk__margin;
                }
            }
        }
    }

    .field {
        .note {
            display: block;
        }

        .price-notice {
            @extend .abs-adjustment-incl-excl-tax;
        }
    }
    div.mage-error[generated] {
      display: none !important;
    }
}

.product-options-wrapper {
    &:after {
        content: '';
        visibility: hidden;
        display: block;
        height: 0;
        clear: both;
    }
}

.product-info-main,
.product-options-bottom {
    .price-box {
        .price-including-tax + .price-excluding-tax,
        .weee + .price-excluding-tax,
        .weee {
            font-size: 12px;
            line-height: 14px;
            margin-bottom: $indent__xs;

            .price {
                font-size: 12px;
                font-weight: $font-weight__bold;
            }
        }

        .price-wrapper .price {
            font-size: 24px;
            font-weight: $font-weight__bold;
        }

        .price {
            white-space: nowrap;
        }
    }

    .special-price {
        display: block;
        margin: $indent__s 0;

        .price-container {
            font-size: 14px;
        }

        .price-label + .price-wrapper {
            display: inline-block;
        }
    }

    .old-price,
    .special-price {
        .price-label {
            &:after {
                content: ': ';
            }
        }
    }

    .box-tocart {
        margin: 0;

        &:after {
            content: '';
            visibility: hidden;
            display: block;
            height: 0;
            clear: both;
        }

        .fieldset {
            width: 100%;
        }
        .center-fieldset{
          display: flex;
          justify-content: center;

          .product-price span{
              line-height: 53px;
          }
          button.action.primary.tocart{
            float: none;
            margin: 7px 0;
          }
        }

        .field.qty {
            padding-right: 0.75 * $indent__base;
        }

        .input-text.qty {
            $tocart-input-size: $button__line-height__l + 28px;
            height: 42px;
            text-align: center;
            width: 42px;
        }

        .actions {
            text-align: center;
            float: left;
        }

        .action.tocart {
            @extend .abs-button-l;
        }
    }

    .product-addto-links {
        margin: $indent__base 0;
    }

    .action.tocompare {
        @extend .abs-action-addto-product;
        vertical-align: top;
    }
    .product-contact{
      display: flex;
      .product-contact-left{
        width: 60%;
        text-align: center;
        margin: auto;
        display: block;
        font-size: 10px;
        padding: 8px 20px;
        border: 1px solid;
        border-radius: 30px;
        cursor: pointer;
        position: relative;
        font-size: 13px;
        margin-bottom: 30px !important;
        span{
          display: block;
          margin: auto;
          text-align: center;
        }
      }
      .product-contact-left-email{
        text-align: left;
      }
      .product-contact-right{
        text-align:left;
        span a{
          position: relative;
          text-decoration:none;
          padding-left: 30px;
          &:before{
            content: "";
            position: absolute;
            width: 20px;
            height: 100%;
            top: 0;
            left: 0;
            background: transparent url("../images/arrowdown.svg") no-repeat 96% 50%;
            background-size: 100%;
            -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
          }
        }
      }
    }
    .product-options-bottom{
      .product-contact{
        display: block;
        .product-contact-left{
          width: 60%;
          display: block;
          margin: auto;
          span{
            display: block;
            margin: auto;
            text-align: center;
          }
        }
      }
    }
    .product-add-form{
      .action{
        &.towishlist{
          font-size: 14px;
          margin: 10px 0 20px 61px;
          &:before{
            content:'';
            background-image: url(../images/star-wishlist-catalog.svg);
            height: 25px;
            line-height: 30px;
            margin: 0 5px -3px 0;
            display: inline-block;
            overflow: hidden;
            font-weight: 800;
            width: 25px;
            background-size: cover;
          }
          span{
            font-size: 16px;
            line-height: 17px;
            display: inline-block;
            vertical-align: middle;
            height: 28px;
          }
        }
      }
    }
}

.page-layout-1column {
    .product-info-price {
        padding-right: 1.4285714286rem;
        margin-top: 1.5rem;
        margin-bottom: 20px;
    }

    .product-options-bottom {
        @include max-screen($screen__m) {
            width: 100%;
            padding-left: 0;
        }
    }
}

.prices-tier {
    @extend .abs-reset-list;
    background: $sidebar__background-color;
    margin: $indent__s 0;
    padding: $indent__s (0.75 * $indent__base);

    .price-container {
        display: inline-block;
    }

    .price-including-tax,
    .price-excluding-tax,
    .weee {
        display: inline-block;

        .price {
            font-size: 14px;
            font-weight: $font-weight__bold;
        }
    }
}

.ui-dialog-titlebar-close {
    @include lib-button-as-link();
}

.block.related {
  clear: both;
  margin-top: 30px;
    .action.select {
        margin: 0 $indent__xs;
    }
    .field.choice.related, .product-item-actions, .block-actions{
      display: none;
    }
    .product-item-info{
      margin: auto;
    }
}

//
//  Sidebar product view
//  ---------------------------------------------

.sidebar {
    .product-items {
        .product-item {
            margin-bottom: $indent__base;
            position: relative;

            &-info {
                position: relative;
                width: auto;

                .product-item-photo {
                    left: 0;
                    position: absolute;
                    top: 0;
                }
            }

            &-name {
                margin-top: 0;
            }

            &-details {
                margin: 0 0 0 85px;
            }

            &-actions {
                display: block;
                margin-top: $indent__s;
            }
        }

        .price-box {
            display: block;
            margin: 7px 0;
        }

        .text {
            margin-right: 8px;
        }

        .counter {
            color: $primary__color__lighter;
            font-size: 12px;
            white-space: nowrap;
        }

        .minilist {
            .price {
                display: inline;
                padding: 0;
            }

            .weee:before {
                display: inline-block;
            }
        }
    }

    .action {
        &.delete {
            @extend .abs-remove-button-for-blocks;
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    .subtitle {
        @extend .abs-no-display;
    }

    //
    //  Product images only
    //  ---------------------------------------------

    .product-items-images {
        @extend .abs-add-clearfix;
        margin-left: -$indent__xs;

        .product-item {
            @extend .abs-add-box-sizing;
            float: left;
            padding-left: $indent__xs;
        }
    }

    //
    //  Product names only
    //  ---------------------------------------------

    .product-items-names {
        .product-item {
            margin-bottom: $indent__s;
        }

        .product-item-name {
            margin: 0;
        }
    }
}

.lg-download {
    display: none;
}

.back-btn:visited,
.back-btn {
    z-index: -1;
    position: absolute;
    opacity: 0;
    top: 35%;
    color: $color-maroon;

}


.btn-attr-select {
    background: transparent;
    border: 0;
    font-size: 28px;
    position: absolute;

    &:focus,
    &:hover {
        background: transparent;
        border: 0;
    }
}


#back-to-top-btn {
    left: 15px;
    padding-left: 35px;

    &:before {
        content: ' ';
        background: transparent url(../images/up-arrow.svg) no-repeat;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 30px;
        height: 20px;
    }
}


#back-btn {
    right: 0;
    padding-left: 40px;
    position: relative;

    &:before {
        content: ' ';
        background: transparent url(../images/rwd.svg) no-repeat;
        position: absolute;
        left: 0;
        top: 5px;
        width: 24px;
        height: 24px;
    }
}


.back-btn {
    &.visible {
        opacity: 1;
        z-index: 10;
    }
}

.next-btn {
    right: 25px;

}

.back-btn,
.prev-btn {
    left: 25px;
}
.product-add-form {
    .product-options-wrapper {
        .fieldset {
            margin-bottom: 10px;
          }
        }
        .swatch-opt{
          margin-top: 0;
        }
        .swatch-attribute-label::after {
          content: '*';
          color: red;
        }
      }
//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {

    .fifty {
        width: 100%;
    }
    body {
        &.product-super-jet-helmets {
            .product.data.items >  {
                .data.item.content {
                    margin: -3.5% auto 0;
                    .product.attribute.description {
                        .value {
                            p:first-child {
                                width: 100%;
                                float: none;
                            }
                        }
                    }
                }
            }
        }
    }




    body {
        #map {
            width: 80%;
            margin: 0 auto;
            height: 560px;
        }
        .product.info.detailed {
            .product.data.items >  {
                .data.item.content {
                    min-height: 500px;
                }
            }
        }

        .btn-attr-select {
            font-size: 18px;
        }
        #back-btn {
            position: absolute;
        }

        .back-btn {
            top: 63%;
        }

        .fieldset > .field {
            margin: 0 0 0;
        }

        .product-add-form .product-options-wrapper {

            .table-wrapper .fieldset > .field .control select {
                background-size: 30px;
            }

            .overlay-wrapper {

                h6 {
                    padding: 10px;
                }

                .step {
                    margin: 10px;
                    padding: 30px;
                    border: 0;
                    border-bottom: 3px solid;

                    &.step-3 {
                        padding: 0;

                        .table-wrapper .fieldset > .field:not(.choice) > p {
                            float: left;
                        }
                    }

                    &.step-1 {
                        p {
                            display: none;
                        }
                    }

                }


                .table-wrapper {
                    margin: 0;
                    border: 0;

                    .fieldset > .field {
                        border-bottom: 0;

                        &:not(.choice) {

                            > label.label {
                                width: 15%;
                            }

                            > .control {
                                width: 70%;

                            }

                            > p {
                                width: 82%;
                                padding-left: 25px;
                                float: none;
                            }
                        }
                    }

                    p {
                        padding: 10px;
                    }


                    .col-2,
                    .col-3,
                    .col-4,
                    .right-col,
                    .left-col {
                        width: 100%;
                        border: 0;
                        padding: 0;
                        margin-bottom: 20px;
                    }
                }

                .measure-me li {
                    width: 50%;
                    margin-top: 30%;
                }


            }
        }

    }

    body {

        .map-wrap {
            img {
                max-width: 50%;
            }
        }
    }



    .catalog-product-view {
        .column.main {
            display: flex;
            flex-direction: column;
        }
    }

    .product-info-main{
      .box-tocart {
        .actions {
            .action.tocart {
                @extend .abs-button-responsive-smaller;
            }
        }
        .center-fieldset{
          display: flex;
          .product-price {
            text-align: center;
          }
        }
      }
      .product-add-form{
        .action{
          &.towishlist{
            width: 100%;
            text-align: center;
            margin-left: 0;
            margin-right: 0;
            &:before{
              margin: 0 5px 0 -5px;
            }
          }
        }
      }
    }
    .product-info-main .product-contact{
      display: block;
      .product-contact-left{
        text-align: center;
        display: block;
      }
    }

    .block.related {
        .action.select {
            display: block;
            margin: auto;
        }
        .product-items{
          margin-left: 0;
          .product-item{
            width: 49%;
            padding-left: 0;
            .product-item-details{
              padding-left: 0;
            }
            .price-box{
              margin-left: 10px 0;
            }
          }
        }
    }

    .compare,
    .product-addto-links .action.tocompare,
    .product-item-actions .actions-secondary > .action.tocompare,
    [class*='block-compare'] {
        display: none;
    }
    .field.qty{
      .control{
        margin: 7px;
      }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {

    body {
        #map {
            width: 80%;
            margin: 0 auto;
            height: 860px;
        }
    }

    // NEW BUTTON STYLES

    .product-add-form {
        text-align: left;
        width: 100%;
        float: right;

        .product-options-wrapper {

            .fieldset {
                @include lib-clearfix();

                > .field {
                    width: 50%;
                    float: left;

                }
            }
        }

    }
    .product-info-main,
    .product-options-bottom {
        .box-tocart {

            .field.qty {
                float: right;
                display: table-cell;
                width: 70px;
                padding-top: 7px;
            }

            .actions {
                text-align: center;
                vertical-align: bottom;
                float: left;
                width: 52%;
            }

            .center-fieldset{
              .actions{
                width: calc(100% - 70px);
              }
            }
        }
    }

    .product-social-links {
        display: none;
        position: absolute;
        right: 0;
        bottom: 40px;
    }

    .sidebar {
        .product-items {
            .product-item {
                &-info {
                    .product-item-photo {
                        float: left;
                        left: auto;
                        margin: 0 $indent__s $indent__s 0;
                        position: relative;
                        top: auto;
                    }
                }

                &-details {
                    margin: 0;
                }

                &-actions {
                    clear: left;
                }
            }
        }
    }

    .product-add-form {
        @extend .abs-revert-field-type-desktop;
    }

    a#ui-id-31{
      pointer-events: none;
    }
    .catalog-product-view{
      .page-title{
        font-size: 59px;
      }
    }
}

//
//  Desktop large
//  _____________________________________________

@include min-screen($screen__xl) {
    .sidebar {
        .product-items {
            .product-item {
                &-info {
                    .product-item-photo {
                        position: absolute;
                        top: 0;
                        left: 0;
                        margin: 0;
                    }
                }

                &-details {
                    margin-left: 85px;
                }
            }
        }
    }
}

//
//  Category page layout
//  ---------------------------------------------

@include min-screen($screen__l) {
    .product-info-main {
        float: right;
    }

    .product.media {
        float: left;
    }

    .page-layout-1column {
        .product-info-main {
            width: 50%;
            box-sizing: border-box;

            &.static {
                position: static;
            }
        }
        .product.media {
            width: 50%;
        }

        &.category-is-all-accessories,
        &.product-lewis-leathers-canvas-bags-2 {
            .product-info-main {
                width: 80%;
                margin: 0 auto;
            }

            .product.media {
                width: 100%;
            }

            .product-add-form {
                margin: 0;
            }

            .product-info-main .box-tocart,
            .product-options-bottom .box-tocart {
                margin: 0;
            }
        }
    }

    .page-layout-2columns-left,
    .page-layout-2columns-right,
    .page-layout-3columns {
        .product-info-main {
            width: 48%;
        }

        .product.media {
            width: 0%;
        }
    }
}

//
//  Compare Products Page
//  ---------------------------------------------

body.catalog-product-compare-index {
    .action.print {
        float: right;
        margin: 15px 0;
    }
}

.table-wrapper.comparison {
    clear: both;
    max-width: 100%;
    overflow-x: auto;
}

.table-comparison {
    table-layout: fixed;

    .cell.label.remove,
    .cell.label.product {
        span {
            @extend .abs-visually-hidden;
        }
    }

    .cell.label,
    td:last-child {
        border-right: $table__border-width $table__border-style $table__border-color;
    }

    .cell {
        padding: 15px;
        width: 140px;

        .attribute.value {
            overflow: hidden;
            width: 100%;
        }

        &.product.info,
        &.product.label {
            border-bottom: $table__border-width $table__border-style $table__border-color;
        }

        &.label {
            .attribute.label {
                display: block;
                width: 100%;
                word-wrap: break-word;
            }
        }

        &.attribute {
            font-size: 13px;
            img {
                height: auto;
                max-width: 100%;
            }
        }
    }

    .product-item-photo {
        display: block;
        margin: 0 auto 15px;
    }

    .product-image-photo {
        margin-left: 0;
    }

    .product-item-actions,
    .price-box,
    .product.rating,
    .product-item-name {
        display: block;
        margin: 15px 0;
    }

    .product-addto-links {
        margin-top: 15px;

        .action.split,
        .action.toggle {
            @include lib-button-s();
        }

        .action.toggle {
            padding: 0;
        }
    }

    .cell.remove {
        padding-bottom: 0;
        padding-top: 0;
        text-align: right;

        .action.delete {
            @extend .abs-remove-button-for-blocks;
        }
    }

    .product-item-actions {
        > .actions-primary {
            + .actions-secondary {
                margin-top: $indent__s;
            }
        }
    }

    .action {
        &.tocart {
            white-space: nowrap;
        }
    }
}

.comparison.headings {
    background: $page__background-color;
    left: 0;
    position: absolute;
    top: 0;
    width: auto;
    z-index: 2;
}

.block.block-questions-dashboard td{
    padding: 11px 10px;
}
.block-compare {
    .block-title {
        @extend .abs-block-title;
    }

    .product-item .product-item-name {
        margin-left: 22px;
    }

    .action {
        &.delete {
            @extend .abs-remove-button-for-blocks;
            left: -6px;
            position: absolute;
            top: 0;
        }

        &.compare {
            @extend .abs-revert-secondary-color;
        }
    }

    .counter {
        @extend .abs-block-items-counter;
    }

    .actions-toolbar {
        margin: 17px 0 0;
    }
}

///OVERIDES////

.cms-home {
    .columns.span-6 {
        img {
            min-height: 0;
        }

    }

}
.red-border {
    border: 2px solid red;
}

.header.panel > .header.links > li.greet.welcome {
    display: none;
}

.measure-me {
    width: 75%;
    margin: 60px auto 0;
    padding: 0;
    @include lib-clearfix();

    li {
        display: inline-block;
        float: left;
        width: 25%;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 50%;
        min-height: 135px;

        &:nth-child(1) {
            background-image: url(../images/waist-size.png);
        }

        &:nth-child(2) {
            background-image: url(../images/arm-length.png);
        }

        &:nth-child(3) {
            background-image: url(../images/chest-size.png);
        }

        &:nth-child(4) {
            background-image: url(../images/inside-leg.png);
        }
    }
}

body {

    &.category-find-us {


        .category-description {
            margin-bottom: 0;
        }

        .page-main {
            padding: 0;
            color: $color-black;

            table,
            a,
            a:visited,
            .alink:visited,
            h1,
            p,
            h2,
            h3,
            h4,
            h5 {
                color: $color-black;
            }

            h1 {
                margin-top: 0;
            }

            .inner {
                background: #f3bc14;

                .columns {
                    display: none;
                }
            }
        }
    }

    .submenu.ui-menu {
        li.all-category {
            display: none;
        }
    }


    .lg-outer .lg-image.lg-object {
        height: 100% !important;
    }

    .tg {
        td,
        th {
            border: none;
            text-align: left;
            padding: 1px 5px;
            font-family: "q3sc", sans-serif;
        }

        th {
            font-size: 18px;
        }
    }


    #your-gallery {
        background-image: url('../images/moto-loader.gif');
        background-position: center;
        background-repeat: no-repeat;

        a {
            transition: opacity 0.3s;
            opacity: 0;
        }

        &.loaded {
            background-image: none;

            a {
                opacity: 1;
            }

        }

        img {
            width: 20%;
            margin-right: 2.5%;
            margin-bottom: 2.5%;

            &:hover {
                transform: scale(1.05);
            }

            &.no-animation {
                &:hover {
                    transform: scale(1);
                }
            }
        }
    }

    .map-wrap {
        max-width: 100%;
        margin: 0% auto;
        background: #f3bc14;
        padding: 0 10% 60px;

        >a {
            display: block;
            text-align: center;
            padding-bottom: 20px;
            font-size: 20px;
        }

        >div {
            margin-bottom: 1.5rem;
            @include lib-clearfix();

            &.red-border {
                padding: 10px;
            }
        }
    }


    //colour selector
    .overlay-wrapper {
        background: url(../images/bg-weather.jpg);
        background-size: 100%;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        transition: opacity, 1s ease-in-out;
        opacity: 0;
        text-align: center;
        padding-bottom: 40px;

        &.visible {
            opacity: 1;

        }

        &[data-role="selector"] {
            z-index: 5;
            .step {
                &.error {
                    background: rgba(255, 0, 0, 0.3);
                }
            }
        }

        &.details {
            z-index: 5;


            &:before {
                content: '×';
                font-size: 60px;
                position: absolute;
                top: 0;
                right: 0;
                height: 100px;
                width: 100px;
                z-index: 10;
                cursor: pointer;
            }
        }

        h6 {
            letter-spacing: 3px;
        }

        .step {
            border: 3px solid;
            margin: 30px;
            transition: background-color, 1s ease;
            background: rgba(255, 0, 0, 0);

            &.error {
                background: rgba(255, 0, 0, 0.3);

                &.step-3 {
                    background: transparent;
                }
            }

            &.step-3 {
                .col-2 {
                    >img {
                        width: 100%;
                    }
                }
            }
        }

        .measure-me,
        .table-wrapper {
            @include lib-clearfix();

        }

        #closeBtn {
            position: absolute;
            right: 1rem;
            top: 1rem;
            font-size: 2rem;
            cursor: pointer;
        }

        .color-list {
            padding: 0;

            li {
                list-style: none;
                display: inline-block;
                margin-right: 1rem;
                min-height: 370px;
                background-repeat: no-repeat;
                background-position: top center;
                position: relative;
                cursor: pointer;
                background-color: transparent;
                transition: background-color, 1s ease-in-out;

                &:hover {
                    background-color: rgba(140, 140, 140, 0.5);
                }

                p {
                    text-align: center;
                    font-family: 'q3sc', sans-serif;
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                }

                &.White {
                    background-image: url(../images/white.png);
                }
                &.Red {
                    background-image: url(../images/red.png);
                }
                &.Black {
                    background-image: url(../images/black.png);
                }
                &.Green {
                    background-image: url(../images/green.png);
                }
                &.Blue {
                    background-image: url(../images/blue.png);
                }
                &.brown {
                    background-image: url(../images/brown.png);
                }
            }
        }

    }

    .fotorama__thumb {
        background: transparent;
    }

    .product.media {
        .fotorama__stage__frame .fotorama__img {
            width: auto;
            padding: 0 20px;
            box-sizing: border-box;
        }
    }

    .product-image-wrapper {
        overflow: visible;
    }

    .opc-wrapper {
        width: 100%;
        float: none;

        .checkout-payment-method {
            width: 100%;
            margin: 0 auto;
            .payment-method, .discount-code {
                max-width: 500px;
                width: auto;
                text-align: left;
            }
        }
    }

    .product.data.items >  {
        .data.item.content {
            background-color: transparent;
            border: 0;
            float: none;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: top;
            margin: 0 auto 0;
            padding: 0;

            .product.attribute.description {
                text-align: left;

                p {
                    font-size: 1rem;
                }
            }
        }
    }

    &.product-super-jet-helmets {
        .product.data.items >  {
            .data.item.content {
                margin: -3.5% auto 0;

                .value {
                    p:first-child {
                        width: 50%;
                        float: right;
                    }
                }
            }
        }
    }
    .product-info-stock-sku,
    .product.data.items > .item.title,
    &.page-products .products-grid .product-items .actions-primary {
        display: none;
    }
}
@supports (gap: 1px) {
  body .overlay-wrapper {
    background: url('../images/bg-weather.webp');
  }
}

body.fotorama__fullscreen {

    >.fotorama {
        background: url(../images/overlay-bg.jpg);
        background-size: cover;
        position: absolute !important;
        overflow-y: scroll;

        &:before {
            content: '';
            position: absolute;
            height: 100%;
            width: 80%;
            bottom: 0;
            z-index: 10;
            left: 50%;
            transform: translateX(-50%);
        }

        &:after {
            content: '';
            position: absolute;
            bottom: -20%;
            width: 100%;
            height: 300px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: 700px;
        }

    }

    &.product-easy-rider-no-69er {
        >.fotorama {
            &:after {
                background-image: url(../images/easy-rider-jacket-detail.png);
            }
        }

    }

    &.product-bronx-jacket-no-384 {
        >.fotorama {
            &:after {
                background-image: url(../images/bronx-jacket-detail.png);
            }
        }

    }

    &.product-star-lightning-no-392 {
        >.fotorama {
            &:after {
                background-image: url(../images/star-lightning-392-jacket-detail.png);
            }
        }

    }

    &.product-western-jkt-no-988 {
        >.fotorama {
            &:after {
                background-image: url(../images/western-988-jacket-detail.png);
            }
        }

    }

    &.product-universal-racer-mk-1 {
        >.fotorama {
            &:after {
                background-image: url(../images/universal-jacket-mk1-detail.png); //adjust png
            }
        }

    }

    &.product-universal-racer-mk-2 {
        >.fotorama {
            &:after {
                background-image: url(../images/universal-racer-mk-2-detail.png);//adjust png
            }
        }

    }

    &.product-twin-track-bronx-no-440 {
        >.fotorama {
            &:after {
                background-image: url(../images/bronx-jacket-detail.png);
            }
        }

    }

    &.product-super-monza-no-445 {
        >.fotorama {
            &:after {
                background-image: url(../images/super-monza-445-jacket-detail.png);
            }
        }

    }

    &.product-racing-jacket-no-442 {
        >.fotorama {
            &:after {
                background-image: url(../images/racing-jacket-442-detail.png);
            }
        }

    }

    &.product-roadmaster-no-414-mk1-2 {
        >.fotorama {
            &:after {
                background-image: url(../images/road-master-414-detail.png);//adjust png
            }
        }

    }

    &.product-the-corsair-no-60 {
        >.fotorama {
            &:after {
                background-image: url(../images/corsair-jacket-detail.png);
            }
        }

    }

    &.product-sportsman-no-69 {
        >.fotorama {
            &:after {
                background-image: url(../images/sportsman-jacket-detail.png);
            }
        }

    }

    &.product-super-sportsman-no-68 {
        >.fotorama {
            &:after {
                background-image: url(../images/super-sportsman-jacket-detail.png);

            }
        }
    }

    &.product-countryman-no-385 {
        >.fotorama {
            &:after {
                background-image: url(../images/countryman-jacket-detail.png);
            }
        }

    }

    &.product-super-phantom-no-443 {
        >.fotorama {
            &:after {
                background-image: url(../images/super-phantom-443-jacket-detail.png);
            }
        }

    }

    &.product-europa-jacket-no-439 {
        >.fotorama {
            &:after {
                background-image: url(../images/europa-jacket-detail.png);
            }
        }

    }

    &.product-cyclone-jacket-no-441 {
        >.fotorama {
            &:after {
                background-image: url(../images/cyclone-jacket-detail.png);
            }
        }

    }


    &.product-black-arrow-no-62 {
        >.fotorama {
            &:after {
                background-image: url(../images/black-arrow-detail.png);
            }
        }

    }

    &.product-lightning-no-402 {
        >.fotorama {
            &:after {
                background-image: url(../images/lightning-402-jacket-detail.png);
            }
        }

    }

    &.product-flying-suit-no-207 {
        >.fotorama {
            &:after {
                background-image: url(../images/easy-rider-jacket-detail.png); //check
            }
        }

    }

    &.product-lumber-jacket-no-699 {
        >.fotorama {
            &:after {
                background-image: url(../images/lumber-jacket-699-detail.png);
            }
        }

    }

    &.product-mudlarker-jacket-no-641 {
        >.fotorama {
            &:after {
                background-image: url(../images/mudlarker-641-jacket-detail.png); //check
            }
        }

    }

}

.field .control._with-tooltip input {
    float: left;
}

.checkout-index-index {
    .column.main {
        background: url(../images/man.png) no-repeat 90% 190px;
        background-size: 300px;
    }
}

.more-info-link {
    display: none;
}

.leather-type-wrapper {
    position: relative;
    padding-bottom: 120%;
    .leather-type {
        position: absolute;
        opacity: 0;
        transition: opacity 0.75s linear;
        &.visible {
            opacity: 1;
        }
    }
}
.page-products .page-title{
  text-align: left;
  .base{
    font-size: 16px;
  }
}
@include max-screen($screen__m) {

    .checkout-index-index {
        .column.main {
            background: transparent;
        }
    }
    body {
        .more-info-link {
            display: block;
        }

        .page-title-wrapper {
            margin-bottom: 0;
            padding-top: 0;
        }
        .map-wrap {
            padding: 0 10% 60px;
        }
        .overlay-wrapper {
            &.details {
                overflow-y: scroll;
                -webkit-overflow-scrolling: touch;
                position: fixed;
                height: 100%;
                padding-top: 3rem;
                z-index: 120;

                &:before {
                    font-size: 50px;
                    line-height: 50px;
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 50px;
                }

                >img {
                    padding-bottom: 30%;
                }
            }
        }
    }
}
.product-info-price .price-label {
    display: none !important;
}
.swatch-input.super-attribute-select{
   position: absolute;
   left: -1000px;
   visibility: hidden;
}
.configurable-variation-qty{
  display: none;
}
.options-wrapper{
  .group-container {
    .label{
      display:none;
      }
    .control{
      margin-bottom: 10px;
      margin-right: 10px;
      select{
        height: 100%;
        background: transparent url("../images/arrowdown.svg") no-repeat 96% 50%;
        appearance: none;
        background-size: 6%;
      }
    }
    .field{
      display: table;
      width: 50%;
      float: left;
    }
    display: none;
    margin-top: 10px;
    &:after{
      content: " ";
      display: block;
      clear: both;
    }
  }
  .group-title{
    font-size: 18px;
    cursor: pointer;
    position: relative;
    padding: 3px 0;
      &:before{
        content: "";
        position: absolute;
        width: 20px;
        height: 100%;
        top: 0;
        right: 0;
        background: transparent url("../images/arrowdown.svg") no-repeat 96% 50%;
        background-size: 100%;
        transition: transform .25s ease;
      }
      &:after{
        content: '*';
        color: red;
      }
  }
  &.active{
    .group-title{
      &:before{
        transform: rotate(180deg);
        transition: transform .25s ease;
      }
    }
  }
}
@include max-screen($screen__m) {
  .options-wrapper{
     .group-container {
       .field {
          width: 100%;
        }
        .control{
          margin-right: 0;
        }
      }
  }
}
.page-layout-1column .product-info-main {
    padding: 0 10px;
    background-color: #fffff6;
}
@include max-screen($screen__l) {
  .page-layout-1column .product-info-main {
    margin-top: 25px;
  }
}
.product-contact-left:after{
  content: "";
  position: absolute;
  bottom: -23px;
  width: 0;
  height: 0;
  border-left: 19px solid transparent;
  border-top: 23px solid #fffff6;;
  border-right: 19px solid transparent;
  left: calc(50% - 19px);
}
.product-contact-left::before {
    content: "";
    position: absolute;
    bottom: -26px;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-top: 26px solid;
    border-right: 20px solid transparent;
    left: calc(50% - 20px);
}
.catalog-category-view .page-title-wrapper {
    display: none;
}
.back-in-stock-configurable-link{
  padding: 0 10px 10px 10px;
}
.modal-footer button.action.primary{
  box-shadow: inset 0 0 3px #e2dec5;
  background-color: #4B1B0E;
  color: #fff;
}
.catalog-product-view{
  .product-info-stock-sku{
    display: block;
  }
}
.price-base_price .price-wrapper .price{
    font-size: 24px;
&::before{
    content: "From: ";
    font-size: 24px;

}}
.product-options-bottom .price-final_price .price{
    &::before{
        content: "Total: ";
    }
}

.price-box.price-final_price {
    padding-right: 1.4285714286rem;
    margin-top: 1.5rem;
    margin-bottom: 20px;
}

.product-item .price-box .price-label {
    font-size: 14px;
  }
