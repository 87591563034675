.pages {
    @include lib-pager();

    .action {
        width: 34px;

        &.previous {
            margin-right: 0;
            }


        &.next {
            margin-left: 0;
            }

    }
    strong.action.previous:hover::before, strong.action.next:hover::before{
      color: inherit;
    }
}
