body .product.data.items > .data.item.content .product.attribute.description
{
    table,
    th,
    td {
        border-color: transparent;
        padding: 0 3px;
        @include max-screen($screen__m) {
            width: 100% !important;
            display: block;
            float: left;
            clear: both;
            table > tbody > tr > td {
                width: initial !important;
                display: table-cell;
                float: none;
                clear: none;
                text-align: center;
                img {
                    margin: 0 auto;
                    display: block;
                }
            }
            table {
                padding: 0;
                display: table;
            }
        }
    }
    table,
    th,
    td,
    p {
        font-size: 1rem;
        font-weight: normal;
        letter-spacing: 0.02rem;
        line-height: 1.7142857143rem;
        text-align: justify;
    }
    p.caption {
        font-size: 0.875rem;
        line-height: 0.9835714286rem;
        text-align: center;
        margin-bottom: 14px;
    }
    p.capital-paragraph::first-letter {
      font-size: 2.5714285714rem;
    }
    table {
        padding: 0 10px 25px 10px;
        &.four-column {
            width: 50%;
            float: left;
        }
        @include max-screen($screen__m) {
            padding: 0 10px;
            margin-bottom: 0;
        }
    }
    td {
        width: 50%;
        text-align: center;
        table > tbody > tr > td {
            width: 33.333333333333336%;
            text-align: center;
            img {
                -webkit-filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.57));
                filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.57));
            }
        }
        @include max-screen($screen__m) {
            table.four-column > tbody > tr > td {
                width: 50% !important;
            }
        }
    }
    td:first-child, th:first-child {
        padding-right: 6px;
        padding-left: 0;
        @include max-screen($screen__m) {
            padding: 0;
        }
    }
    td:last-child, th:last-child {
        padding-right: 0;
        padding-left: 6px;
        @include max-screen($screen__m) {
            padding: 0;
        }
    }
    @include max-screen($screen__m) {
        td > table > tbody > tr > td:first-child {
            padding-right: 6px;

        }
        td > table > tbody > tr > td:last-child {
            padding-left: 6px;
        }
    }
}

body.category-is-all-footwear .product.data.items > .data.item.content .product.attribute.description
{
    td {
        width: 33.3333333333%;
    }
}
