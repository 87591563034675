@font-face {
  font-family: 'customer-question';
  src:  url('fonts/customer-question.eot?t29cdu');
  src:  url('fonts/customer-question.eot?t29cdu#iefix') format('embedded-opentype'),
    url('fonts/customer-question.ttf?t29cdu') format('truetype'),
    url('fonts/customer-question.woff?t29cdu') format('woff'),
    url('fonts/customer-question.svg?t29cdu#customer-question') format('svg');
  font-weight: normal;
  font-style: normal;
}
.box-question-list {
    border: 0px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 3rem;
    .block-title {
        margin-bottom: 3rem;
        strong {
            font-weight: 300;
            line-height: 1.1;
            font-size: 2.6rem;
        }
    }
}
.next-question {
    margin-top: 3rem;
}
.question-list {
    margin-bottom: 2rem;
    ul.parent {
        padding: 0px;
        margin: 0px;
        list-style: none;
    }
    ul.parent > li {
        margin-bottom: 25px;
        border: 0px;
    }
    ul.parent > li:last-child {
        margin-bottom: 0px;
        border: 0px;
        padding-bottom: 0px;
    }
    ul.answers {
        list-style: none;
        margin-top: 15px;
        border: 1px solid #ccc;
        margin-left: 20px;
        padding: 10px;
        border-style: dashed;
        position: relative;
        li {
            margin-bottom: 25px;
        }
        li:last-child {
            margin: 0px;
        }
        .question-add {
            display: block;
            border: 0px;
            textarea.mage-error:focus {
                border-color: #ed8380;
            }
            textarea + div.mage-error {
                padding: 5px !important;
                margin: 0px !important;
                border: 1px solid #ccc !important;
                border-top: 0px !important;
            }
        }
    }
    ul.answers:before {
        content: '';
        position: absolute;
        top: -7px;
        left: 10px;
        border-top: 1px dashed #ccc;
        border-left: 1px dashed #ccc;
        transform: rotate(36deg) skew(-20deg);
        width: 10px;
        height: 10px;
        background-color: #fff;
    }
    ul.reply {
        list-style: initial;
        padding: 0px;
        margin: 0px;
        margin-top: 5px;
        color: #bbbbbb;
        li.reply-on span:before {
            font-family: 'customer-question';
            content: "\e900";
            padding-right: 5px;
        }
        li {
            display: inline-block;
            border: 0px;
            margin: 0px;
            padding: 0px;
            padding-right: 15px;
            cursor: pointer;
        }
        .like {
            span.loading-number {
                background-repeat: no-repeat;
                background-position: left center;
                padding-right: 18px;
            }
        }
        .dislike {
            span.loading-number {
                background-repeat: no-repeat;
                background-position: center center;
                padding-right: 18px;
            }
        }
        .like:before,
        .dislike:before {
            font-family: 'customer-question';
            content: "\e902";
            padding-right: 5px;
        }
        .dislike {
            position: relative;
            span {
                padding-left: 10px;
            }
        }
        .dislike:before {
            content: "\e901";
            position: absolute;
            top: 2px;
        }
        li:hover,
        li:focus {
            color: #575757;
        }
    }
    ul.parent > li p {
        margin-bottom: 5px;
    }
    ul.parent > li p.table {
        display: flex;
    }
    ul.parent > li p.table .first-character {
        width: 30px;
        height: 30px;
        background: #ddd;
        margin-right: 7px;
        text-align: center;
        text-transform: uppercase;
        font-size: 15px;
        line-height: 30px;
        font-weight: 600;
        display: block;
        background-color: #ddd;
        color: #7d7d7d;
    }
    ul.parent > li p.table .author-name {
        overflow: hidden;
        line-height: 13px;
        text-transform: capitalize;
        color: #514943;
    }
    ul.parent > li p.table .time {
        font-size: 71%;
        display: block;
        margin-top: 5px;
        font-weight: 600;
    }
    ul.parent > li p.table .like {
        margin-right: 15px;
    }
}
.question-add {
    .block-title {
        margin-bottom: 2rem;
        strong {
            font-weight: 300;
            line-height: 1.1;
            font-size: 2.6rem;
        }
    }
    .question-policy {
        float: left;
        line-height: 2.1;
        a {
            color: #555;
            position: relative;
        }
        a:hover {
            color: #1979c3;
            text-decoration: none;
        }
        b {
            padding-right: 5px;
        }
    }
    .question-policy a:after {
        font-family: 'customer-question';
        content: "\e9a8";
        font-size: 17px;
        position: absolute;
        top: -7px;
    }
    textarea {
        margin-bottom: -5px;
        width: 100%;
        outline: none;
        border-radius: 3px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }
    textarea:focus {
        outline: none;
        box-shadow: none;
        border-color: #2188ff;
    }
    textarea.mage-error:focus {
        border-color: #ed8380;
    }
    textarea + div.mage-error {
        padding: 5px !important;
        margin: 0px !important;
        border: 1px solid #ccc !important;
        border-top: 0px !important;
    }
    .question-form-actions {
        display: block;
        overflow: hidden;
        padding: 5px 10px;
        margin: 0px;
        border: 1px solid #ccc;
        border-top: 0px;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
        .actions-primary {
            float: right;
            button {
                margin: 0px;
            }
            button.cancel {
                margin-left: 15px;
            }
        }
    }
}
.question-load-more {
    text-align: center;
    margin-bottom: 2rem;
    a {
        position: relative;
        span {
            padding-right: 10px;
        }
    }
    a:hover {
        text-decoration: none;
    }
    a:after {
        content: "\e903";
        font-family: 'customer-question';
        font-size: 17px;
        position: absolute;
        top: -2px;
    }
}
.action-actived {
    color: rgb(87, 87, 87);
}
button.cancel,
.hidden-input,
.img-loading {
    display: none;
}
@media(max-width: 767px) {
    .question-add {
        .question-form-actions {
            .actions-primary {
                float: initial;
                button.cancel {
                    margin: 10px 0px;
                }
            }
        }
    }
}
.questions-container{
  display: none;
  float: right;
  width: 100%;
}
.question-add .question-policy {
  display: none;
}

.questions-container .question-add textarea{
  margin-bottom: 0;
}
.questions-container button.action.primary.submit, .question-form-popup-actions button.action.primary.submit{
  box-shadow: inset 0 0 3px #e2dec5;
}
.questions-container .box-question-list .block-title strong, .questions-container .question-add .block-title strong{
  font-size: 21px;
}
.questions-container .block-title{
  text-align: center;
}
.question-add .question-form-actions {
  border: none;
  margin: 10px;
}
.actions-toolbar.question-form-popup-actions{
  margin-top: 20px;
}
