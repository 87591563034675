//
//  Variables
//  _____________________________________________

$checkout-summary__background-color: $color-white-smoke !default;
$checkout-summary__padding         : 22px $indent__l !default;

$checkout-summary-title__margin    : $indent__s !default;
$checkout-summary-mark-value__color: $color-gray60 !default;

$checkout-summary-items__max-height: 370px !default;
$checkout-summary-items__padding   : 15px !default;

//
//  Order Summary
//  _____________________________________________

.opc-block-summary {
    @extend .abs-add-box-sizing;
    background: transparent;
    padding: $checkout-summary__padding;
    margin: 0 0 $indent__base;
    width: 63%;

    & > .title {
        @extend .abs-checkout-title;
        display: block;
    }

    .table-totals {
        @extend .abs-sidebar-totals;
    }

    .mark {
        .value {
            color: $checkout-summary-mark-value__color;
            display: block;
        }
    }

    .grand.incl {
        & + .grand.excl {
            .mark,
            .amount {
                border-top: 0;
                font-size: 14px;
                padding-top: 0;

                strong {
                    font-weight: $font-weight__regular;
                }
            }
        }
    }

    .not-calculated {
        font-style: italic;
    }

    //
    //  Items list
    //  _____________________________________________

    .items-in-cart {
        & > .title {
            border-bottom: $border-width__base solid $border-color__base;
            padding: $indent__s $indent__xl $indent__s 0;
            cursor: pointer;
            @include lib-icon-font(
                $icon-down,
                $_icon-font-size       : 30px,
                $_icon-font-line-height: 12px,
                $_icon-font-text-hide  : true,
                $_icon-font-margin     : 3px 0 0,
                $_icon-font-position   : after,
                $_icon-font-display    : block
            );
            margin-bottom: 0;
            position: relative;

            &:after {
                position: absolute;
                right: 0;
                top: $indent__s;
            }

            strong {
                font-size: 18px;
                font-weight: $font-weight__light;
                margin: 0;
            }
        }

        &.active {
            & > .title {
                @include lib-icon-font-symbol(
                    $_icon-font-content : $icon-up,
                    $_icon-font-position: after
                );
            }
        }

        .product {
            position: relative;
        }
    }

    //
    //  Cart items
    //  _____________________________________________

    .minicart-items-wrapper {
        margin: 0 (-$checkout-summary-items__padding) 0 0;
        //max-height: $checkout-summary-items__max-height;
        padding: $checkout-summary-items__padding $checkout-summary-items__padding 0 0;
        border: 0;
    }

    .column.main & {
        .product-item {
            margin: 0;
            padding-left: 0;
        }
    }

    .product-item {
        .product-item-inner {
            display: table;
            margin: 0 0 $indent__s;
            width: 100%;
        }

        .product-item-name-block {
            display: table-cell;
            padding-right: $indent__xs;
            text-align: left;
        }

        .subtotal {
            display: table-cell;
            text-align: right;
        }

        .price {
            font-size: 16px;
            font-weight: $font-weight__regular;
        }

        .price-including-tax {
            & + .price-excluding-tax {
                margin: 0;

                .price {
                    font-size: 10px;
                }
            }
        }

    }
}

dl {
    &.product.options.list {
        @include lib-clearfix();
        padding-top: 10px;
        margin-bottom: 5px;
        dd,
        dt {
            width: 50%;
            margin-bottom: 5px;
        }

        dt {
            float: left;
        }

        dd {
            float: right;
        }
    }
}

.item-options {
    margin: 10px auto;

    dd,
    dt {
        width: 50%;
    }

    dd {
        float: left;
        margin-bottom: 5px;
    }

    dt {
        float: left;
        width: 90px;
        overflow: hidden;
        height: 20px;
        text-overflow: ellipsis;
        word-break: break-all;
    }
}

.opc-block-summary,
.checkout-shipping-method {
    .step-title {
        font-size: 26px;
    }
}
#my-orders-table {
    th, .product-item-name{
    font-size: 15px;
  }
}
//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .opc-summary-wrapper {
        .modal-header {
            .action-close {
                display: none;
            }
        }
    }
}
@include min-screen($screen__s) {
 .sales-guest-view{
   .item-options {
    margin: 10px 0 20px 10px;
    text-align: left;
    display: table;
    width: 100%;
  }
 }
}
