//
//  Variables
//  _____________________________________________

$form-field__vertical-indent__desktop: 29px;
$form-calendar-icon__color: $primary__color;

//
//  Common
//  _____________________________________________

.fieldset {
    @include lib-form-fieldset();
    &:last-child {
        margin-bottom: 0;
    }

    > .field {
        @include lib-clearfix();

    }

    & > .field,
    & > .fields > .field {
        @include lib-form-field();

        &.no-label {
            > .label {
                @extend .abs-visually-hidden;
            }
        }

        &.choice {
            .label {
                display: inline;
                font-weight: normal;
            }
        }

        .label {
            .column:not(.main) & {
                font-weight: normal;
            }
        }

        .field.choice {
            margin-bottom: $indent__s;

            &:last-child {
                margin-bottom: 0;
            }
        }

        input[type=file] {
            margin: $indent__xs 0;
        }
    }
}

.legend + .fieldset,
.legend + div {
    clear: both;
}

.legend {
    strong {
        margin-left: $indent__xs;
    }
}

fieldset.field {
    border: 0;
    padding: 0;
}

.field {
    &.date {
        @extend .abs-field-date;

        .time-picker {
            display: inline-block;
            margin-top: $indent__s;
            white-space: nowrap;
        }
    }

    .message {
        &.warning {
            margin-top: $indent__s;
        }
    }
}

div.mage-error[generated] {
    margin-top: 7px;
}

.field .tooltip {
    @include lib-tooltip(right);
    .tooltip-content {
        min-width: 200px;
        white-space: normal;
    }
}

input,
select {
    &:focus ~ .tooltip .tooltip-content {
        display: block;
    }
}

._has-datepicker {
    ~ .ui-datepicker-trigger {
        @include lib-button-reset();
        @include lib-icon-font(
            $_icon-font-content    : $icon-calendar,
            $_icon-font-color      : $primary__color__lighter,
            $_icon-font-size       : $icon-calendar__font-size,
            $_icon-font-line-height: $icon-calendar__font-size,
            $_icon-font-display    : inline-block,
            $_icon-font-text-hide  : true
        );
        vertical-align: middle;

        &:focus {
            box-shadow: none;
            outline: 0;
        }
    }
}

//
//  Sidebar forms
//  -----------------------------------------

.sidebar {
    .fieldset {
        margin: 0;

        & > .field:not(.choice),
        .fields > .field {
            &:not(:last-child) {
                margin: 0 0 $form-field__vertical-indent;
            }

            .label {
                margin: 0 0 4px;
                padding: 0 0 $indent__xs;
                text-align: left;
                width: 100%;
            }

            .control {
                width: 100%;
            }
        }
    }
}


button.action.primary,
.super-attribute-select,
.control select {
    padding: 5px 30px;
    position: relative;
    cursor: pointer;
    color:  $color-maroon;
    border: 3px solid $color-maroon;
    border-radius: 10px;
    overflow: hidden;
    background-color: transparent;
    display: block;
    text-align: left;
    transition: background-color 0.75s ease;
    max-width: 320px;

    &.submit {
        background-color: $color-maroon;
        border-color: $color-maroon;
        color: $color-white;
    }

    &.checkout,
    &.tocart {
        background-color: $color-maroon;
        border-color: $color-maroon;
        color: $color-white;
        float: left;
        //text-align: center;
        width: 90%;
        margin: 7px auto;
        max-width: 220px;
        text-transform: capitalize;
        box-shadow: inset 0 0 3px #e2dec5;
        @media(max-width:926px){
            line-height: 21px !important;
        }
        @media(max-width:768px){
            line-height: 32px !important;
        }

        &:before {
            height: 30px;
            width: 30px;
            content: '\e611';
            position: absolute;
            right: 15px;
            font-family: icons-blank-theme;
            font-size: 30px;
        }

        span {
            font-size: 14px;

        }


        .icon {
            fill: $color-white;
            position: absolute;
            right: 25px;
            top: 1px;
            height: 25px;
        }

        label {
            font: font(default);

        }
    }
    &.add, &.save, &.create, &.continue{
      background-color: $color-maroon;
      border-color: $color-maroon;
      color: $color-white;
      box-shadow: inset 0 0 3px #e2dec5;
      width: auto;
    }

    &.subscribe {
        height: auto;
        padding: 7px 30px;
        border: 1px solid;
    }
}

.product-info-main {

    text-align: left;


}

.box {
    border: 3px solid;
    min-height: 226px;
    box-sizing: border-box;
}

.fifty {
    padding: 15px;
    float: left;
    width: 49%;
    margin-right: 10px;
    margin-bottom: 10px;

    &.last {
        float: right;
        margin-right: 0;
    }
}


.product-add-form {

    .box-tocart,
    .product-options-wrapper {

        //overlay form layout
        .table-wrapper {
            margin: 0 20px;

            &:last-child {
                .box {
                    padding: 10px;
                    min-height: 0;
                    margin-bottom: 10px;
                }
            }

            &.border {
                border: 3px solid;
                border-bottom: 0;
            }

            &.arms {
                .fieldset {
                    position: relative;
                    border: 0;

                    .field {
                        border: 0;
                    }
                }
            }
            &.hips {
                margin-bottom: 15px;
                .left-col {
                    height: 68px;
                    border-bottom: 3px solid;

                }

                .right-col {
                    .fieldset > .field:last-child {
                        border-bottom: 3px solid;
                        @include max-screen($screen__xs) {
                            border: 0;
                        }
                    }
                }
            }

            &.extras,
            &.tell-us-if {
                .fieldset > .field:not(.choice) > {
                    .control {
                        float: none;
                        margin: 0 auto;
                        width: 50%;
                    }
                }
            }

            .col-2,
            .col-3,
            .col-4,
            .right-col,
            .left-col {
                box-sizing: border-box;
                float: left;

            }


            .left-col {
                width: 33%;
                padding: 10px;
                border-right: 3px solid;
            }

            .right-col {
                width: 67%;
                float: right;
                box-sizing: border-box;

            }

            .col-2 {
                width: 50%;
                padding: 0 45px;
            }

            .col-3 {
                cursor: pointer;
                width: 33%;
                padding: 45px;

                h6 {
                    margin-bottom: 25px;
                }

                img {
                    cursor: pointer;
                    box-shadow: 2px 2px 5px #505050;
                }
            }

            .col-4 {
                width: 25%;
                margin-bottom: 3rem;
            }


            .fieldset {


                > .field {
                    border-bottom: 3px solid;
                    padding: 8px;

                    @include max-screen($screen__xs) {
                        border: 0;
                    }



                    &:not(.choice) {

                        p {
                            float: right;
                            width: 55%;
                            padding-right: 10px;
                        }

                        > .control {
                            float: left;
                            width: 33%;
                            margin-left: 30px;
                        }

                        > label.label {
                            width: 12%;
                            padding-left: 25px;
                        }
                    }
                }
            }
        }


        .fieldset > .field:not(.choice)  {

            >.control {
                margin: 10px 0;

                select,
                select.super-attribute-select {
                    background: transparent url("../images/arrowdown.svg") no-repeat 96% 50%;
                    color: $color-maroon;
                    border-color: $color-maroon;
                    cursor: pointer;
                    font-family: 'q3sc', sans-serif;
                    height: 100%;
                    margin: 0 auto;
                    width: 98%;
                    appearance: none;
                    background-size: 6%;

                    &:focus {
                        outline: none;
                    }

                }

                &.double {

                    select {
                        width: 30%;
                    }

                }

            }

            &.qty {
                .control {
                    width: 56%;
                }
            }

        }


        .fieldset > .field > label.label {
            width: 32px;
            line-height: 44px;
            font-family: $font-family__serif;
            float: left;
            height: 100%;
            vertical-align: middle;
            padding: 0;
            display: none;
        }

    }

}
.product-add-form .box-tocart .fieldset > .field:not(.choice) > .control {
  margin: 0;
}
.product-arrows::before {
    font-family: icons-blank-theme;
    content: '\e619';
    font-size: 50px;
}
select {

    &.sorter-options {
        background-color: $color-maroon;
        color: $color-white;
    }
}



//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .fieldset {
        .legend {
            @extend .abs-margin-for-forms-desktop;
        }

        > .field {
            @include lib-form-field-type-revert();
            margin: 0 0 $form-field__vertical-indent__desktop;
        }
    }
}
