.contact-index-index, .sales-guest-form{
    .fieldset{
        .field{
            .label{
                text-align: left;
            }
        }
    }
    .store-details{
      background-color: $background-cart-yellow;
      border-bottom: 2px solid $border-checkout-bottom-orange;
      margin-left: 1rem;
      text-align: left;
      padding: 10px 5px 20px;
    }
    .form-title{
      text-align: left;
      font-size: 16px;
      margin-bottom: 5px;
      font-family: 'q3';
    }
    .column form{
      text-align: left;
      background-color: $background-cart-yellow;
      border-bottom: 2px solid $border-checkout-bottom-orange;
      button.action.primary.submit{
          margin: 20px 7px;
          margin-bottom: 27px;
          box-shadow: inset 0 0 3px #e2dec5;
          padding: 5px 15px;
          line-height: 32px;
          font-size: 18px;
        }
        .fieldset{
          margin: 0;
          padding-top: 3px;
        }
        .control{
          select{
            padding: 0 5px;
            max-width: 100%;
          }
        }
        legend{
          margin-bottom: 20px;
        }
        .field{
          padding: 7px;
        }
    }
    .field.note.no-label::before {
      padding: 0;
      width: 0;
  }
  .field.required > .label::after {
      content: '*';
      color: #e02b27;
      font-family: inherit;
      font-style: inherit;
      font-weight: inherit;
      line-height: inherit;
      font-size: 12px;
      margin: 0;
  }
}
.contact-index-index{
  .page-title-wrapper {
    margin-bottom: 0;
  }

}
@include max-screen($screen__s) {
  .contact-index-index, .sales-guest-form{
      .column form{
        button.action.primary.submit{
          width: auto;
        }
      }
      .store-details{
        margin-left: 0;
        margin-top: 2rem;
      }
  }

}
@include min-screen($screen__m){
  .contact-row{
    display: flex;
    flex-wrap: wrap;
    float: left;
    width: 100%;
  }
}
