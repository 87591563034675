$layout-column-main__sidebar-offset: 2%;
$layout-column__additional-sidebar-offset: $layout-column-main__sidebar-offset;

//
//  Common
//  _____________________________________________

.columns {
    @include lib-layout-columns();

    .column.main {
        @extend .abs-add-box-sizing;
        padding-bottom: $indent__xl;
        flex-basis: 100%;
        flex-grow: 1;
        order: 1;
    }

    .sidebar-main {
        @extend .abs-add-box-sizing;
        flex-grow: 1;
        flex-basis: 100%;
        order: 1;
    }

    .sidebar-additional {
        @extend .abs-add-box-sizing;
        flex-grow: 1;
        flex-basis: 100%;
        order: 2;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .navigation,
    .breadcrumbs,
    .page-header .header.panel,
    .header.content,
    .footer.content,
    .page-main,
    .page-wrapper > .widget,
    .page-wrapper > .page-bottom,
    .block.category.event,
    .top-container {
        padding-left: $layout__width-xs-indent;
        padding-right: $layout__width-xs-indent;
        padding-top: 25px;

    }



    .page-main {
        .account &,
        .cms-privacy-policy & {
            padding-top: 41px;
            position: relative;
        }
    }

    .page-wrapper {
        .nav-sections,
        .header.panel {
            background-color: $color-black;
        }

    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .navigation,
    .breadcrumbs,
    .page-header .header.panel,
    .header.content,
    .footer.content,
    .page-wrapper > .widget,
    .page-wrapper > .page-bottom,
    .block.category.event,
    .top-container,
    .page-main {
        box-sizing: border-box;
        margin-left: auto;
        margin-right: auto;
        max-width: $layout__max-width;
        @include min-screen($screen__xxl) {
            max-width: $layout-large__max-width;
        }
        padding-left: $layout-column_indent_width;
        padding-right: $layout-column_indent_width;
        @include min-screen($screen__xxl) {
            padding-left: $layout-indent__width;
            padding-right: $layout-indent__width;
        }
        width: auto;
    }

    .page-header .panel.wrapper .panel.header{
      padding-right: 25px;
    }
    body.overlay-active {
        .page-main {
            min-height: 1690px;
        }
        .action.mailto.friend {
            display: none;
        }
    }

    .page-main {
        width: 100%;
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: auto;
        .ie9 & {
            width: auto;
        }
    }

    .columns {
        display: block;
    }

    .column.main {
        @extend .abs-add-box-sizing-desktop;
        @include lib-layout-columns__main();
        min-height: 300px;
    }

    .sidebar-main {
        @extend .abs-add-box-sizing-desktop;
        @include lib-layout-columns__left();
        padding-right: $layout-column-main__sidebar-offset;
    }

    .page-layout-2columns-right .sidebar-main {
        padding-left: $layout-column-main__sidebar-offset;
        padding-right: 0;
    }

    .sidebar-additional {
        @extend .abs-add-box-sizing-desktop;
        @include lib-layout-columns__right();
        clear: right;
        padding-left: $layout-column__additional-sidebar-offset;
    }

    .page-layout-2columns-left {
        .sidebar-additional {
            clear: left;
            float: left;
            padding-left: 0;
            padding-right: $layout-column__additional-sidebar-offset;
        }
    }

    .panel.header {
        padding: 10px 20px;
        background-color: $color-black;

    }

    .span-3,
    .span-4,
    .span-6 {
        float: left;

        &.last {
            float: right;
        }
    }

    .span-3 {
        width: 23.7288135593%;
    }

    .span-4 {
        width: 33.3333%;
    }

    .span-6 {
        width: 49%;
    }

    .span-12 {
        float: left;
        margin-left: 0;
        margin-right: 0;
        width: 100%;
    }


}


@include max-screen($screen__s) {
    .fifty {
        width: 100%;
    }
}
