a.action.primary,
button {
    border-radius: 3px;
}
button {
    &:active {
        box-shadow: $button__shadow;
    }
}
a.action.primary {
    @include lib-link-as-button();
    border-radius: 10px;
}
.action.primary {
    @include lib-button-primary();
    border: 3px solid #4B1B0E;
    color: inherit;
}
