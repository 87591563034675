//
//  Common
//  _____________________________________________

body {
    font-family: 'q3sc', sans-serif;
    background-image: url(../images/background_tile.jpg);
    background-color: #d31f24;

    &.no-scroll {
        overflow-y: hidden;
    }
}


@keyframes fadeIn {
    from {
        max-height: 0;
        opacity: 0;

        } to {

        max-height: 1000px;
        opacity: 1;

        }
}
.grid {
    .columns {
        h6,
        img {
            opacity: 0;  // make things invisible upon start
            animation: fadeIn ease-in 1; // call our keyframe named fadeIn, use animattion ease-in and repeat it only 1 time

            animation-fill-mode: forwards;  // this makes sure that after animation is done we remain at the last keyframe value (opacity: 1)

            animation-duration: 0.75s;
            animation-delay: 1.5s;
        }

        img {
            height: 300px;
            width: auto;
        }
    }
    .row {
        padding-bottom: 3rem;
    }
}

//
//  Header
//  ---------------------------------------------

table {
    font-family: $font-family__serif;
    color: $color-maroon;
}

th {
    font-weight: bold;
}

table > thead > tr > th,
table > thead > tr > td,
table > tbody > tr > th,
table > tbody > tr > td,
table > tfoot > tr > th,
table > tfoot > tr > td {
    padding: 0 25px;

}

td,
th {

    &:first-child {
        padding: 0;
    }
}

.page-header {
    width: 100%;
    position: sticky;
    z-index: 99;
    top: 0;
    position: -webkit-sticky;
    height: 126px;
    .header.content {
        background-position: top center;
        background-repeat: no-repeat;
        background-size: contain;
        background-color: $color-black;
    }

    ul.header {
        a,
        a:visited {
            color: $color-yellow;
        }
    }

    .banner {
        display: block;
        height: 85px;
        width: 100%;
    }
}

main {

    height: 100%;
    background: url('../images/bg-weather_short_law.jpg');
    background-size: 100%;
    overflow: visible;
    padding: 9px;
    text-align: center;
    position: relative;
}
@supports (gap: 1px) {
  main {
    background: url('../images/bg-weather_short_law.webp');
    background-size: 100%;
  }
}

a,
p {
    font-weight: 100;
    text-align: justify;

    &::first-letter {
        //font-size: 200%;
    }

    &.center {
        text-align: center;
    }

}

p {
    font-family: $font-family__serif;
    color: $color-maroon2;
}

img {
    transition: all 1.75s ease-in-out;
    transform: scale(1);
    //&:hover {
    //    transform: scale(1.1);
    //}

    &.full-width {
        position: absolute;
        left: 0;
        z-index: 1;
    }

    &.no-animation {
        &:hover {
            transform: scale(1);
        }
    }
}




.featured-product {
    padding: 1rem;
    border: 5px solid;
    margin: 15px;
}

.the-leaders {
    padding-top: 54.5%;
    background:  url(../img/the-leaders.png) transparent no-repeat center;
    background-size: 80%;
}

nav.navigation {

    background: url(../images/btn-strip.jpg);
    width: 100%;
    opacity: 1;
    z-index: 10;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;

    @include breakpoint(sm) {
        height: auto;
    }

    ul {
        margin: 0;
        overflow-y: hidden;
        overflow-x: scroll;
        white-space: nowrap;
        width: auto;
        font-family: 'q3sc';

        @include breakpoint(sm) {
            display: block;
            text-align: center;
            overflow: visible;
            //justify-content: space-around;
        }
    }

    li.level0 {
        color: $color-yellow;
        position: relative;
        display: inline-block;
        background: url(../images/nav-li-bg.jpg) no-repeat;
        background-size: contain;
        padding: 12px 0;
        cursor: pointer;
        @include breakpoint(sm) {
            width: 16.66666%;
            text-align: center;
        }

        .submenu {
            background: url(../images/bg2.jpg);
            background-position: 10px 300px;

            a:hover {
                background: rgba(181, 173, 173, 0.5);
            }
        }

        &:last-child {

            &:after {
                background-color: transparent;
            }
        }

        a.level-top {
            color: $color-yellow;
            font-weight: 100;
            font-size: rem-calc(18);
        }
    }


}
@supports (gap: 1px) {
  nav.navigation li.level0 .submenu {
    background: url('../images/bg2.webp');
  }
}
.header {
    &.content {
        @extend .abs-add-clearfix;
        padding-top: 20px;

        position: relative;
    }
}

.logo {
    display: table;
    width: 100%;
    height: 60px;
    float: left;
    position: absolute;
    z-index: 5;
    left: 0;
    top: 0;
    text-align: center;

    img {
        display: inline-block;
        width: auto;
        vertical-align: top;
        height: 50px;
        margin-top: 0;
    }

    .page-print & {
        float: none;
    }
    .inner-logo{
      display: inline-block;
      height: 60px;
      max-height: 60px;
      width: 70%;
      text-align: center;
      vertical-align: bottom;
      color: #c6a540;
      font-family: 'q3sc';
    }
    .logo-text-title{
      display: block;
      font-size: 47px;
      line-height: 40px;
      letter-spacing: 12px;
      font-variant: small-caps;
      word-spacing: 8px;
    }
    .logo-text-subtitle{
      line-height: 0px;
      font-size: 11px;
      letter-spacing: 3px;
      font-variant: all-small-caps;
      word-spacing: 8px;
    }
}

.page-main {
    & > .page-title-wrapper {
        .page-title + .action {
            margin-top: $indent__l;
        }
    }
}

.action.skip {
    &:not(:focus) {
        @extend .abs-visually-hidden;
    }

    &:focus {
        background: $color-gray94;
        padding: $indent__s;
        box-sizing: border-box;
        left: 0;
        position: absolute;
        text-align: center;
        top: 0;
        width: 100%;
        z-index: 15;
    }
}

//
//  Global notice
//  ---------------------------------------------

.message.global {
    p {
        margin: 0;
    }

    &.noscript,
    &.cookie {
        @include lib-message($_message-type: global-note);
        margin: 0;
    }

    &.cookie {
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        z-index: 3;

        .actions {
            margin-top: $indent__s;
        }
    }

    &.demo {
        @include lib-message($_message-type: global-caution);
        margin-bottom: 0;
        text-align: center;
    }
}

//
//  Footer
//  ---------------------------------------------

.page-footer {
    margin-top: auto;

    .nav.item {
        a {
            color: $color-yellow;
            text-decoration: none;

            &:hover {
                color: #9f8533;
            }
        }
        &.current{
          color: #8F270A;
        }
    }
}

.footer {
    &.content {
        padding-bottom: 25px;
        padding-top: 25px;
        background-color: #000;

        ul {
            @extend .abs-reset-list;
        }

        .links {
            & > li {
                margin: 0 0 8px;
            }
        }

        .switcher-store {
            margin: 0 0 30px;
        }
    }

    .copyright,
    .bugs {
        display: block;
        margin: 20px 0 0;
        color: $color-yellow;

    }

    input {
        background: #000;
        border: 1px solid #c6a540;
        color: #c6a540;
    }
    .payment-methods{
      text-align: right;
      display: flex;
      justify-content: flex-end;
      div{
          display: flex;
          padding-bottom: 15px;
      }
      img{
          max-width: 55px;
          max-height: 35px;
          margin-right: 10px;
      }
    }
}

.page-header,
.page-footer {
    .switcher {
        margin-right: 10px;

        .options {
            @include lib-dropdown(
                $_dropdown-actions-padding           : 0,
                $_dropdown-list-item-padding         : 0,
                $_dropdown-toggle-icon-content       : $icon-down,
                $_dropdown-toggle-active-icon-content: $icon-up,
                $_icon-font-text-hide                : true,
                $_icon-font-size                     : 22px,
                $_icon-font-line-height              : 22px,
                $_dropdown-list-min-width            : 160px
            );

            ul.dropdown {
                a {
                    display: block;
                    padding: 8px;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }

        li {
            font-size: $font-size__s;
            margin: 0;
        }

        .label {
            @extend .abs-visually-hidden;
        }

        strong {
            font-weight: $font-weight__regular;
        }
    }
}

//
//  Widgets
//  ---------------------------------------------

.sidebar {
    .widget.block:not(:last-child),
    .widget:not(:last-child) {
        @extend .abs-margin-for-blocks-and-widgets;
    }
}

.widget {
    clear: both;

    .block-title {
        @extend .abs-block-title;
    }
}

.page-header,
.page-footer {
    .widget.block {
        margin: $indent__base 0;
    }
}

.no-display {
    @extend .abs-no-display;
}

//
//  Calendar
//  ---------------------------------------------

.ui-datepicker {
    td {
        padding: 0;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {

    html,
    body {
        height: 100%;
    }

    .fotorama__nav-wrap {
        display: none !important;
        height: 0;
    }

    .page-wrapper {
        display: flex;
        flex-direction: column;
        min-height: 100%;

        & > .breadcrumbs,
        & > .top-container,
        & > .widget {
            box-sizing: border-box;
            width: 100%;
        }

        .ie10 &,
        .ie11 & {
            height: 100%;
        }
    }

    .navigation ul {
        padding: 0 8px;
    }

    .header {
        &.panel {
            & > .header.links {
                display: inline-block;
                font-size: 0;
                @include lib-list-inline();
                margin-left: auto;
                margin-right: 10px;

                & > li {
                    font-size: $font-size__base;
                    margin: 0 0 0 15px;

                    &.welcome,
                    a {
                        line-height: 1.4;
                        font-size: 11px;
                        text-decoration: none;
                    }

                    &.welcome {
                        color:  $color-yellow;
                        a {
                            padding-left: $indent__xs;
                        }
                    }
                }
            }
        }

        &.content {
            @extend .abs-add-clearfix-desktop;
            padding: 10px 20px 44px;
        }
    }

    .page-header {
        border: 0;
        margin-bottom: 0;

        .header.panel {
            @extend .abs-add-clearfix-desktop;
            padding-bottom: $indent__s;
            padding-top: $indent__s;
            text-align: right;
            height: 40px;
        }

        .switcher {
            display: inline-block;
        }
        .header.content{
          height: 87px;
        }
    }

    .page-main {
        & > .page-title-wrapper {
            .page-title {
                display: inline-block;
            }

            .page-title + .action {
                float: right;
                margin-top: $indent__base;
            }
        }
    }

    .logo {
        margin: 0 auto 0 0;

        img {
            max-height: inherit;
        }
    }

    .footer {
        &.content {
            .block {
                float: none;
            }

            .links {
                display: inline-block;
                margin-bottom: 20px;
                padding: 0 50px 0 0;
                vertical-align: top;
            }

            .switcher.store {
                display: inline-block;
                padding-right: 50px;
                vertical-align: top;
            }
        }

        .copyright {
            @include lib-clearfix();
        }
    }
    .customer-welcome {
      position: relative;
      .customer-menu {
        display: none;
        .header.links{
          border: 1px solid $color-yellow;
          padding: 5px;
          &:before{
            border: 6px solid;
            border-color: transparent transparent $color-black transparent;
            z-index: 99;
            left: auto;
            right: 6px;
            top: -12px;
            content: '';
            display: block;
            height: 0;
            position: absolute;
            width: 0;
          }
          &:after{
            border: 8px solid;
            border-color: transparent transparent $color-yellow transparent;
            left: auto;
            right: 4px;
            top: -16px;
            content: '';
            display: block;
            height: 0;
            position: absolute;
            width: 0;
          }
        }
      }
      ul{
        margin: 0;
        margin-top: 0px;
        padding: 0;
        list-style: none none;
        margin-top: 4px;
        position: absolute;
        top: 100%;
        right: -20px;
        z-index: 1000;
        text-align: left;
        width: 100px;
        background-color: $color-black;
        border: 1px solid $color-yellow;
        padding: 5px;
      }
      &.active .customer-menu{
        display: block;
      }
      button{
        padding:0;
        color: $color-yellow;
        font-size: 11px;
        text-transform: capitalize;
        &:focus{
          border: none;
        }
      }
      .customer-name:after{
        font-family: 'icons-blank-theme';
        content: '\e622';
        font-size: 25px;
        margin-left: -5px;
        color: $color-yellow;
        margin-top: -5px;
        display: inline-block;
        position: absolute;
        cursor: pointer;
        &.active:after{
          content: '\e621';
        }
      }
    }
  .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9 {
    float: left;
    box-sizing: border-box;
  }
  .col-sm-8 {
    width: 66.66666667%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-4 {
    width: 33.33333333%;
  }
  .col-sm-12{
    width: 100%;
  }
}

.product.attribute {
    .value {
        font-family: $font-family__serif; //'times_bold', times;
        font-weight: 900;

    }
}

.page-title-wrapper {
    margin-bottom: 2rem;
}

.product-options-wrapper {
    .fieldset {
        .field.configurable {
            @include lib-clearfix();
        }
    }
}

.page-products {

    .block.filter {
      float: right;
      margin-right: 150px;
      margin-top: -72px;
      position: relative;
      .block-content{
        background-color: #FFFCE9;
        opacity: 0.9;
        border: 1px solid #c9c9c9;
        position: absolute;
        left: -148px;
        top: 100%;
        width: 218px;
        z-index: 100;
        text-align: left;
        padding: 10px;
      }
    }
      .block.filter .block-content:before {
        border: 6px solid;
        border-color: transparent transparent #fffce9 transparent;
        z-index: 99;
        left: auto;
        right: 4px;
        top: -12px;
        content: '';
        display: block;
        height: 0;
        position: absolute;
        width: 0;
    }
    .block.filter .block-content:after{
      border: 8px solid;
      border-color: transparent transparent #c9c9c9 transparent;
      left: auto;
      right: 2px;
      top: -16px;
      content: '';
      display: block;
      height: 0;
      position: absolute;
      width: 0;
    }
    a{
      text-decoration: none;
    }
}

body.category-press-pr {
    .page-title-wrapper {
        display: none;
    }
}

//TABLET OVERRIDES
@include max-screen($screen__l) {


    body .product.data.items > .data.item.content {
        padding: 0;
        margin: 0 auto 0;
    }
    .inner-logo{
      width: 60% !important;
      .logo-text-title{
        font-size: 3vw;
      }
      .logo-text-subtitle{
        font-size: 10px;
      }
    }
}

body.cms-privacy-policy-cookie-restriction-mode {
    .main {
        text-align: left;
    }
}

///MOBILE OVERIDES -tidy
@include max-screen($screen__s) {

    body {
        .action.mailto.friend {
            display: inline;

            span {
                color: transparent;
            }
        }


        .product-info-main .product.attribute.overview p {
            margin: 0 0 20px;
        }
    }

    body.product-lewis-leathers-canvas-bags-2,
    body.product-bridle-leather-wallet-no-4055,
    body.category-is-all-accessories.product-aviakit-seaboot-socks {
        .page-wrapper {
            .product.media .fotorama__wrap {
                .fotorama__stage {
                    height: 409px !important;
                    .fotorama__stage__frame .fotorama__img {
                        width: 100%;
                    }
                }
            }

            .product.data.items > .data.item.content {
                margin: 0 auto 0;
            }
        }
    }

    body.catalog-product-view .page-main .product.data.items > .data.item.content .product.attribute.description {
        p {
            margin-right: 10px;
        }
    }

    body.category-is-all-jeans.catalog-product-view .page-main .product.data.items > .data.item.content .product.attribute.description {
        position: static;
    }

    .toolbar-amount {
        text-align: center;
    }

    .columns {
        display: block;
    }

    .minicart-wrapper .action.showcart {

        &:before {
            font-size: 25px;
            line-height: 21px;
        }

        .counter.qty {
            min-width: 5px;
            background: transparent;
            padding: 0;
        }
    }

    .page-header {
      background-color: $color-black;
      height: 89px;
        .header.content {
            background-image: none;
            background-color: transparent;
        }
    }

    h1.page-title {
        font-size: 46px;
    }

    nav.navigation {
        background: $color-black;
        li.level0 {
            display: block;
            background: $color-black;
        }
    }

    .breadcrumbs {
        display: none;
    }

    .product-add-form .box-tocart .fieldset > .field,
    .product-add-form .product-options-wrapper .fieldset > .field {

        > label.label {
            width: 29%;

        }

        &.qty {

            > label.label {
                width: 12%;

            }

        }

    }

    .product-info-main .box-tocart .actions,
    .product-options-bottom .box-tocart .actions {
        float: left;
        width: 70%;
    }

    .product-options-bottom {
        .action.mailto.friend {
            &:before,
            span {
                line-height: 45px;
            }
        }
     }

    .product-info-main .box-tocart .fieldset >  .field.qty {
        width: 20%;
        float: right;
        padding: 8px 0 0 0;

        label.label {
            width: 40%;
        }
    }

    .product-add-form .box-tocart .fieldset > .field:not(.choice).qty .control {
        width: auto;
        float: left;
    }


    body .opc-wrapper .checkout-payment-method {

        width: 100%;
        background-position: bottom;

        .product.attribute.description p {
            padding-right: 10px;
        }
    }

    .cms-online-store {

        .grid {

            .columns {
                display: block;
                margin-bottom: 2rem;
            }

            h6,
            img,
            a {
                width: 70%;
                margin: 0 auto;
            }

            img {
                height: auto;
            }

            h6 {
                text-align: center;
            }
        }
    }
    .inner-logo{
        .logo-text-title{
          display: block;
          font-size: 24px;
          line-height: 14px;
          letter-spacing: 2px;
          word-spacing: 5px;
        }
        .logo-text-subtitle{
          line-height: 0px;
          font-size: 10px;
          letter-spacing: 0px;
          font-variant: all-small-caps;
          word-spacing: 5px;
        }
    }
    .greet.welcome {
      display: none;
    }
    .customer-welcome .customer-name button{
      font-size: 1.6rem;
      padding: 0.8rem 20px;
      color: #FFFEEB;
      &:focus{
        border: none;
      }
    }
    .customer-welcome .customer-name{
      display: none;
    }
    .footer{
      .payment-methods{
        text-align: center;
        margin: 20px 0;

        div{
          width: 100%;
          justify-content: center;
        }
      }
    }
}

//
//  Box Shadows
//  ---------------------------------------------

body .product.media .fotorama__stage__frame .fotorama__img,.catalog-category-view .product-image-photo, .upment-topimage-container img {
    -webkit-filter: drop-shadow(12px 12px 12px rgba(0, 0, 0, 0.44));
    filter: drop-shadow(12px 12px 12px rgba(0, 0, 0, 0.44));
}

.box-shadow {
  box-shadow:
  0.3px 0.3px 0.3px rgba(0, 0, 0, 0.16),
  0.7px 0.7px 0.7px rgba(0, 0, 0, 0.23),
  1.4px 1.4px 1.4px rgba(0, 0, 0, 0.285),
  2.5px 2.5px 2.5px rgba(0, 0, 0, 0.34),
  4.6px 4.6px 4.6px rgba(0, 0, 0, 0.41),
  11px 11px 11px rgba(0, 0, 0, 0.57)
;
}
@media(max-width:360px){
  .inner-logo{
    .logo-text-title{
      font-size: 18px;
    }
    .logo-text-subtitle{
      font-size: 8px;
    }
  }

}
.product-item-details{
  padding-left: 100px;
}
.footer-right{
  float: right;
}
.social-icons{
  text-align: right;
  img{
    height: 20px;
  }
  a{
    text-decoration: none;
    margin-right: 20px;
  }
}

@include max-screen($screen__s) {
  .social-icons{
    text-align: center;
    margin: 0 0 20px 0;
    a:last-child{
      margin-right: 0;
    }
  }
  .block.filter{
    display: none;
  }
  .footer .payment-methods{
    display: block;
  }
  .field-container{
    .field-tooltip-action:before{
      margin-top: 8px;
    }
    .fieldset{
      > .field{
        > .label{
          padding: 6px 0 0 0;
          margin: 0;
        }
      }
    }
  }
}
.field-container{
  background-color: $background-cart-yellow;
  border-bottom: 2px solid $border-checkout-bottom-orange;
  text-align: left;
  padding-top: 7px;
  .field{
    padding: 0 7px 7px 7px;
  }
  .field-tooltip{
    .label{
      display: none;
    }
  }
  .field-tooltip-action:before{
    font-size: 18px;
    margin-top: 4px;
    line-height: 18px;
  }
  .actions-toolbar{
    margin: 0;
  }
  button.action.primary.submit{
    margin: 20px 7px;
    margin-bottom: 27px;
    box-shadow: inset 0 0 3px #e2dec5;
    padding: 5px 15px;
    line-height: 32px;
    font-size: 18px;
    width: auto;
  }
  .label{
    float: none !important;
  }
  .control{
    float: none !important;
  }
  .fieldset{
    margin: 0;
    > .field{
      margin: 0;
    }
  }
}
.form-title{
  font-size: 16px;
  font-family: 'q3';
  margin-bottom: 5px;
}
.field.required > .label::after {
    content: '*';
    color: #e02b27;
}
@include min-screen($screen__m){
    .field-container {
      max-width: 500px;
      margin: auto;
    }
}
.cms-subtitle{
  margin: 0 auto 3rem auto;
  p{
    text-align: center;
    margin: 0;
    font-family: 'q3sc';
  }
}
.captcha-reload{
  border: 2px solid;
}
.wishlist_link_header{
  color: $color-yellow;
  font-size: 21px;
  text-decoration: none;
  font-family: 'icons-blank-theme';
  position: relative;
  line-height: 25px;
  .counter.qty{
    position: absolute;
    font-size: 14px;
    left: 11px;
    font-family: 'q3sc';
    background-color: #cc191f;
    border-radius: 50%;
    width: 16px;
    text-align: center;
    color: #fff;
    line-height: 12px;
    top: -5px;
    height: 16px;
    &.empty{
      display: none;
    }
    .wishlist-counter-number{
      line-height: 12px;
    }
  }
}
@include max-screen($screen__s) {
  .wishlist_link_header{
    font-size: 28px;
    font-weight: 600;
    z-index: 999;
    display: block;
    width: 35px;
    text-align: center;
    line-height: 33px;
    .counter.qty{
      height: 18px;
      width: 18px;
      left: 23px;
      top: 4px;
      &.empty{
        display: none;
      }
      .wishlist-counter-number{
        line-height: 14px;
      }
    }

  }
}
