@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path  : '../fonts/opensans/light/opensans-300',
    $font-weight: 300,
    $font-style : normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path  : '../fonts/opensans/regular/opensans-400',
    $font-weight: 400,
    $font-style : normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path  : '../fonts/opensans/semibold/opensans-600',
    $font-weight: 600,
    $font-style : normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path  : '../fonts/opensans/bold/opensans-700',
    $font-weight: 700,
    $font-style : normal
);

@include lib-font-face(
    $family-name: 'q3',
    $font-path  : '../fonts/q3/q-3-webfont',
    $font-weight: 300,
    $font-style : normal
);

@include lib-font-face(
    $family-name: 'q3sc',
    $font-path  : '../fonts/q3sc/q-3sc-webfont',
    $font-weight: 300,
    $font-style : normal
);

@include lib-font-face(
    $family-name: 'times_bold',
    $font-path  : '../fonts/times_bold/times_bold-webfont',
    $font-weight: 300,
    $font-style : normal
);

@include lib-font-face(
    $family-name: 'times_semibold',
    $font-path  : '../fonts/times_semibold/times-semibold-591ded070efbf-webfont',
    $font-weight: 300,
    $font-style : normal
);
@include lib-font-face(
    $family-name: 'q1',
    $font-path  : '../fonts/q1/Q-1',
    $font-weight: 300,
    $font-style : normal
);



.items {
    @include lib-list-reset-styles();
}

.category-stockists {
    h6 {
        text-align: left;
        margin-top: 25px;
    }
}

p.spacer {
    height: 25px;
}

p b,
p strong {
    font-family: 'times_bold';
}

//
//    Desktop
//--------------------------------------

@include min-screen($screen__m) {
    h1 {
        font-size: 66px;
        color: $color-maroon;
        margin-bottom: 10px;
    }
}
