//
//  Variables
//  _____________________________________________

$active-nav-indent: 54px;
$nav-color-white: #FFFEEB;

//
//  Common
//  _____________________________________________

.panel.header {

    .links,
    .switcher {
        display: none;
    }
}

.nav-sections {
    background: transparent;
}

#switcher-language,
#store\.settings {
    display: none !important;
}

.nav-toggle {
    @include lib-icon-font($icon-menu,
        $_icon-font-size : 28px,
        $_icon-font-color : $header-icons-color,
        $_icon-font-color-hover: $header-icons-color-hover );
    @include lib-icon-text-hide();
    cursor: pointer;
    display: block;
    font-size: 0;
    left: 15px;
    position: absolute;
    top: 15px;
    z-index: 14;
}

.switcher-currency {
    font-size: 11px;
    color: $color-yellow;
    line-height: 1.6;

    .switcher-options {
        float: right;
    }
}

.page-header .switcher.switcher-currency .switcher-options .action.toggle::before,
.page-header .switcher.switcher-currency .switcher-options .action.toggle.active::before {
    line-height: 17px;
    vertical-align: bottom;
}

.page-header .switcher .options ul.dropdown {
    left: -5px;
    min-width: 100px !important;

    a {
        margin-left: 17px;
        text-decoration: none;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m - 1) {
    .navigation {
        padding: 0;

        .parent {
            .level-top {
                position: relative;
                @include lib-icon-font($_icon-font-content : $icon-down,
                    $_icon-font-size : 42px,
                    $_icon-font-position: after,
                    $_icon-font-display : block);

                &:after {
                    position: absolute;
                    right: 7px;
                    top: -8px;
                }

                &.ui-state-active {
                    @include lib-icon-font-symbol($_icon-font-content : $icon-up,
                        $_icon-font-position: after);
                }
            }
        }
    }

    .nav-toggle {
        top: -15px;
    }

    .nav-sections {
        -webkit-overflow-scrolling: touch;
        transition: left 0.3s;
        height: 100%;
        left: -100%;
        overflow: auto;
        position: fixed;
        top: 0;
        width: 100%;

        .switcher {
            font-size: 16px;
            font-weight: 700;
            margin: 0;
            padding: 0.8rem 3.4rem 0.8rem 1rem;

            .label {
                display: inline-block;
                margin-bottom: $indent__xs;
            }
        }

        .switcher-trigger {
            strong {
                position: relative;
                @include lib-icon-font($_icon-font-content : $icon-down,
                    $_icon-font-size : 42px,
                    $_icon-font-position: after,
                    $_icon-font-display : block);

                &:after {
                    position: absolute;
                    line-height: 1.4;
                    right: -40px;
                    top: -16px;
                }
            }

            &.active strong {
                @include lib-icon-font-symbol($_icon-font-content : $icon-up,
                    $_icon-font-position: after);
            }

            cursor: pointer;
        }

        .switcher-dropdown {
            @include lib-list-reset-styles();
            padding: $indent__s 0;
            display: none;

            a {
                text-decoration: none;
                color: $color-yellow
            }
        }

        .active {
            .switcher-dropdown {
                display: inline-block;
            }
        }

        .header.links {
            @include lib-list-reset-styles();
            border-bottom: 1px solid $color-gray82;

            li {
                font-size: 1.6rem;
                margin: 0;

                &.greet.welcome {
                    border-top: 1px solid $color-gray82;
                    font-weight: 700;
                    padding: 0.8rem $indent__base;
                }

                &>a {
                    border-top: 1px solid $color-gray82;
                }
            }

            a,
            a:hover {
                color: $nav-color-white;
                text-decoration: $navigation-level0-item__text-decoration;
                display: block;
                font-weight: 700;
                padding: 0.8rem $indent__base;
            }

            .header.links {
                border: 0;
            }
        }
    }

    .switcher-currency {

        .language-EUR:before,
        .currency-EUR a:before {
            background-image: url(../images/eur.png);
            background-size: contain;

        }

        .language-GBP:before,
        .currency-GBP a:before {
            background-image: url(../images/gbp.png);
        }

        .language-USD:before,
        .currency-USD a:before {
            background-image: url(../images/usd.png);
        }

        .language-GBP:before,
        .currency-GBP a:before,
        .language-EUR:before,
        .currency-EUR a:before,
        .language-USD:before,
        .currency-USD a:before {
            background-repeat: no-repeat;
            content: '';
            display: inline-block;
            height: 0.9rem;
            margin-right: 1rem;
            vertical-align: middle;
            width: 1rem;
            margin-left: 4px;
            margin-right: 0;
            background-size: contain;
        }
    }

    .nav-before-open {
        height: 100%;
        overflow-x: hidden;
        width: 100%;

        .page-wrapper {
            transition: left 0.3s;
            height: 100%;
            left: 0;
            overflow: hidden;
            position: relative;
        }

        body {
            height: 100%;
            overflow: hidden;
            position: relative;
            width: 100%;
        }
    }

    .nav-open {
        .page-wrapper {
            left: 80%;
            left: calc(100% - #{$active-nav-indent});
        }

        .nav-sections {
            $_shadow: 0 0 5px 0 rgba(50, 50, 50, 0.75);

            box-shadow: $_shadow;
            left: 0;
            z-index: 99;
        }

        .nav-toggle {
            left: 0;

            &:before {
                content: $icon-remove;
                font-size: 58px;
                line-height: 30px;
            }

            &:after {
                background: rgba(0, 0, 0, 0.7);
                content: '';
                display: block;
                height: 100%;
                position: fixed;
                right: 0;
                top: 0;
                width: 100%;
                z-index: -1;
            }
        }
    }

    .close-nav-button {
        font-family: "icons-blank-theme";
        cursor: pointer;
        padding-top: 10px;
        color: $nav-color-white;
        float: right;
    }

    .close-nav-button:before {
        content: $icon-remove;
        font-size: 58px;
        line-height: 30px;
    }

    .nav-sections-items {
        @include lib-clearfix();
        position: relative;
        z-index: 1;
    }

    .nav-sections-item-title {
        border-width: 0 0 1px 1px;
        box-sizing: border-box;
        float: left;
        height: 71px;
        padding-top: 24px;
        text-align: center;
        width: 33.33%;
        color: $nav-color-white;

        &.active {
            background: transparent;
            border-bottom: 0;
            text-decoration: underline;
        }

        .nav-sections-item-switch {
            color: $color-white;
            text-decoration: none;

            &:hover {
                text-decoration: none;
            }
        }
    }

    .nav-sections-item-content {
        @include lib-clearfix();
        box-sizing: border-box;
        float: right;
        margin-left: -100%;
        margin-top: 71px;
        width: 100%;

        &.active {
            display: block;
        }

        padding: $indent__m 0;
    }

    @include lib-main-navigation();
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .nav-toggle {
        display: none;
    }

    .nav-sections {
        flex-shrink: 0;
        flex-basis: auto;
    }

    .sections.nav-sections {
        position: sticky;
        top: 126px;
        z-index: 98;
        position: -webkit-sticky;
    }

    .nav-sections-item-title {
        display: none;
    }

    .nav-sections-item-content {
        display: block;
    }

    .nav-sections-item-content>* {
        display: none;
    }

    .nav-sections-item-content {
        >.navigation {
            display: block;
        }
    }

    @include lib-main-navigation-desktop();

    .panel.header {

        .links,
        .switcher {
            display: inline-block;
        }
    }

    .switcher-currency {

        .language-EUR:after,
        .currency-EUR a:after {
            background-image: url(../images/eur.png);
            background-size: contain;

        }

        .language-GBP:after,
        .currency-GBP a:after {
            background-image: url(../images/gbp.png);
        }

        .language-USD:after,
        .currency-USD a:after {
            background-image: url(../images/usd.png);
        }

        .language-GBP:after,
        .currency-GBP a:after,
        .language-EUR:after,
        .currency-EUR a:after,
        .language-USD:after,
        .currency-USD a:after {
            background-repeat: no-repeat;
            content: '';
            display: inline-block;
            height: 0.9rem;
            margin-right: 1rem;
            vertical-align: middle;
            width: 1rem;
            margin-left: 4px;
            margin-right: 0;
            background-size: contain;
        }
    }
}