//
//  Variables
//  _____________________________________________

$checkout-wrapper__margin             : $indent__base !default;
$checkout-wrapper__columns            : 8 !default;

$checkout-step-title__border          : $border-width__base solid $color-gray80 !default;
$checkout-step-title__font-size       : 26px !default;
$checkout-step-title__font-weight     : $font-weight__light !default;
$checkout-step-title__padding         : $indent__s !default;

$checkout-step-title-mobile__font-size: 18px !default;

//
//  Common
//  _____________________________________________

.checkout-index-index,
.checkout-onepage-success {
    .page-title-wrapper {
        @extend .abs-visually-hidden;
    }

}
.checkout-onepage-success {
  a.action.primary, .actions-toolbar .primary{
    float: left;
  }
  .actions-toolbar{
    display: table;
  }
}
.checkout-index-index{
  .opc-wrapper{
    input[type="text"]::placeholder, input[type="password"]::placeholder, input[type="url"]::placeholder, input[type="tel"]::placeholder, input[type="search"]::placeholder, input[type="number"]::placeholder, input[type="datetime"]::placeholder, input[type="email"]::placeholder {
    color: transparent;
    }
  }
  .column.main div{
    text-align: left;
  }
  #cookiesdirective div{
    text-align: center;
  }
  #tooltip-label{
    display: none;
  }
  .field.street .field .label{
    display: none;
  }
  .fieldset > .field:not(.choice) > .label {
    text-align: left;
    width: auto;
  }
  .fieldset > .field:not(.choice) > .control {
    width: 100%;
  }
  .control select {
    border-radius: 0;
    background-color: #fff;
    border: 1px solid #c2c2c2;
    max-width: 100%;
    padding: 4px;
  }
  .row {
    -webkit-box-pack: start;
    justify-content: left;
  }
  td p{
    margin-top: 0;
    margin-bottom: 0;
  }
  .column:not(.sidebar-additional) form .actions-toolbar, .column:not(.sidebar-main) form .actions-toolbar{
    margin-left: 0;
  }
  button.action.primary.checkout{
    width: 180px;
  }
  .opc-wrapper button.action.primary{
    color:  $color-white;
    background-color: $color-maroon;
    box-shadow: inset 0 0 3px #e2dec5;
  }
  .checkout-billing-address{
    text-align: left;
    padding: 20px 0;
  }
  .billing-address-details{
    margin-left: 25px;
    display: none;
  }
  #checkout-step-shipping, #checkout-shipping-method-load, #co-payment-form, .opc-sidebar {
    background-color: $background-cart-yellow;
    padding: 20px;
    border-bottom: 2px solid $border-checkout-bottom-orange;
  }
  table, p{
    font-family: 'q3sc';
  }
  #checkout-shipping-method-load {
    table{
      margin-bottom: 0;
    }
    .row{
      margin-bottom: 5px;
      display: inherit;
      &.row-error{
        display: none;
      }
    }
  }
  .minicart-items{
    .product-image-container{
      width: 55px !important;
      height: auto !important;
      padding-left: 20px;
    }
  }
  button.action.action-apply{
    border: 3px solid $color-black;
    border-radius: 10px;
  }
  .form-discount .actions-toolbar .primary{
    float: left;
  }
  .button.action.continue.primary, .action.action-apply {
    background-color: $color-maroon;
    color: #fff;
    box-shadow: inset 0 0 3px #e2dec5;
    margin-top: 10px;
  }
  select{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMCIgaGVpZ2h0PSI2MCIgdmlld0JveD0iMCAwIDMwIDYwIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZmlsbD0iIzMzMyIgZD0iTTE1LjY3NiAzMy4wMzdsNS4xMDItNS4xMDMtLjcwNy0xLjA4Ni00LjQ5IDQuNDkyLTQuNDktNC40OTItLjcwMiAxLjA4NiA1LjEwMiA1LjEwMy4wNzUuMTE1LjAyLS4wMi4wMi4wMnoiLz48L3N2Zz4=');
    background-repeat: no-repeat;
    background-size: auto auto;
    padding-right: 25px;
    background-position: 100% 45%;
  }
  .checkout-payment-method .payment-option-title .action-toggle::after{
    font-size: 30px;
    color: $color-maroon;
  }
  .fieldset > .field .note::before{
    content: none;
  }
  .opayo-payment-method-logo {
    background-image: url(../images/payments/payments.png);
    height: 40px;
  }
  .sagepaysuite-payment-icon{
    max-width: 250px;
  }
  .paypal-payment-method-logo {
    background-size: 50%;
    background-position: left;
  }
  button.action.primary.checkout::before{
    content: '';
  }
  .fieldset > .field:not(.choice) > .control {
    float: left;
  }
  .sq-payment-method{
    .payment-method-title{
      label{
        line-height: 35px;
        padding-right: 10px;
      }
    }
  }
  .square-card-logo{
    height: 35px;
    width: 55px;
    background-size: contain;
    background-repeat: no-repeat;
    padding-right: 10px;
    margin-bottom: 10px;
  }
  .square-mastercard{
    background-image: url(../images/payments/payments-mastercard.png);
  }
  .square-maestro{
    background-image: url(../images/payments/payments-maestro.png);
  }
  .square-visa{
    background-image: url(../images/payments/payments-visa.png);
  }
  .square-visadebit{
    background-image: url(../images/payments/payments-visadebit.png);
  }
  .square-americane{
    background-image: url(../images/payments/payments-americane.png);
  }
  .square-googlepay{
    background-image: url(../images/payments/payments-googlepay-border.png);
  }
  .square-applepay{
    background-image: url(../images/payments/payments-applepay-border.png);
  }
  .square-card-logos{
    display: flex;
    flex-wrap: wrap;
    margin-left: 22px;
  }
  .message.warning {
    background: #fae5e5;
    color: #e02b27;
    &> :first-child::before {
    color: #b30000;
    }
  }
  select[name="country_id"], .action.action-update {
    color: red;
  }
}
.checkout-container {
    @extend .abs-add-clearfix;
    margin: 0 0 $checkout-wrapper__margin;
}

.opc-wrapper {
    margin: 0 0 $checkout-wrapper__margin;

    .opc {
        @extend .abs-reset-list;
    }

    .step-title {
        @extend .abs-checkout-title;
    }

    .step-content {
        margin: 0 0 $indent__xl;
    }
}

.checkout-index-index {
    .nav-sections,
    .nav-toggle {
        display: block;
    }

    .logo {
        margin-left: 0;
    }
    .field .control._with-tooltip input {
      width: 100%;
    }
    .field-tooltip{
      span{
        &.action-help{
          display: inline-block;
        }
        display: none;
      }
    }
}
.info-message-mobile{
  display: none;
}
.info-message {
  padding-top: 16px;
    p{
  color: #ca171d;
  text-align: left;
}
}
//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
  .info-message-mobile{
    display: unset;
       p{
       color: #ca171d;
       text-align: left;
    }
  }
    .opc-wrapper {
        .step-title {
            font-size: $checkout-step-title-mobile__font-size;
            border-bottom: 0;
            padding-bottom: 0;
        }
    }
    .checkout-index-index{
      .payment-group .step-title{
        padding-left: 15px;
      }
      .fieldset > .field:not(.choice) > .control {
        width: 100%;
      }
      .inner .minicart-wrapper{
        z-index: 1;
      }
      .fieldset > .field:not(.choice) > .label {
        width: 100%;
      }
      .custom-slide{
        left: 44px;
      }
      .payment-method .actions-toolbar{
        display: table;
      }
      .opc-block-summary{
        width: 100%;
      }
      .fieldset > .field {
        margin-top: 10px;
      }
      .actions-toolbar > .secondary{
        text-align: left;
      }
      .actions-toolbar > .primary .action{
        width: auto;
      }
      .custom-slide .modal-inner-wrap{
        background: url(../images/bg2.jpg);
      }
      .opc-sidebar{
        background-color: transparent;
      }
      .billing-address-same-as-shipping-block{
        margin-top: 20px;
        margin-bottom: 10px;
      }
      .opc-estimated-wrapper{
        .minicart-wrapper{
          margin-top: 25px;
          .action.showcart{
            padding-top: 5px;
          }
        }
      }
    }
    #estimate-tax-block{
      padding: 0 30px 30px;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .opc-wrapper {
        @extend .abs-add-box-sizing-desktop-m;
        @include lib-layout-column(2, 1, $checkout-wrapper__columns);
        padding-right: $indent__l;
    }

    .checkout-index-index .nav-toggle {
        display: none;
    }
    .checkout-index-index{
      .opc-wrapper .fieldset > .field._required > label::after {
        position: initial;
        margin-left: 0;
      }
      .opc-block-summary, .opc-block-shipping-information{
        padding:0;
        max-width: 500px;
      }
      .opc-block-summary{
        width: 100%;
      }
      .fieldset.address{
         .field {
           width: calc(50% - 0.1px);
           display: inline-block;
           margin: 0;
           vertical-align: top;
           padding: 7px;
        }

        .field.street{
            width: 100%;
            .field{
              width: 100%;
              padding: 0 0 7px 0;
            }
        }
      }
      .opc-wrapper{
        width: 63%;
        float: left;
      }
      #discount-code{
        width: 180px;
      }
      #customer-email-fieldset{
         .control {
          width: 100%;
          }
          .label {
            width: 30%;
          }
        }
    fieldset > .field {
      margin: 0 0 15px;
    }
    .actions-toolbar .primary{
      float: none;
    }
    .actions-toolbar .secondary{
      float: none;
    }
    .form.form-login{
      padding: 7px;
    }
    .billing-address-same-as-shipping-block{
      padding-left: 7px;
    }
    .checkout-billing-address .step-title{
      padding-left: 7px;
    }
  }
}
