//
//  Shopping cart
//  ---------------------------------------------
$background-cart-yellow : #fffff6;
$border-checkout-bottom-orange: #C7A635;
//  Summary block
.cart-summary {
    @extend .abs-add-box-sizing;
    @extend .abs-adjustment-incl-excl-tax;
    margin-bottom: $indent__m;
    padding: 0px 15px $indent__m;

    & > .title {
        display: block;
        @include lib-heading(h3);
        font-size: 12px;
        font-weight: 700;
        text-align: left;
        margin: 0;
        padding-top: 7px 0;
        background-color: $background-cart-yellow;
        padding-left: 4px;
        padding-top: 20px;
    }
    .block.discount{
      text-align: left;
      background-color: $background-cart-yellow;
      padding-bottom: 10px;
      margin-top: 10px;
      border-bottom: 2px solid $border-checkout-bottom-orange;
      .title{
        font-size: 12px;
      }
      .action.apply.primary{
        text-align: right;
        padding: 0;
        font-weight: 100;
        background: none;
        color: inherit;
        font-size: 12px;
        border-bottom: 1px solid;
        border-radius: 0;
      }
      .action.apply.primary:hover{
        border: none;
        color: inherit;
      }
      .action.apply.primary span:before{
        content: '>';
        font-size: 15px;
      }
      .details-title{
        margin-bottom: 15px;
        font-size: 12px;
        padding: 0  7px;
      }
      .fieldset.coupon .field{
        margin:0 10px 10px;
      }
      .actions-toolbar{
        margin: 0;
      }
    }
    .block {
        @extend .abs-discount-block;
        margin-bottom: 0;

        .item-options {
            margin-left: 0;
        }

        .fieldset {
            margin: 15px 0 0 $indent__xs;

            .field {
                margin: 0 0 $indent__s;

                &.note {
                    font-size: $font-size__s;
                }
            }

            .methods {
                .field {
                    & > .label {
                        display: inline;
                    }
                }
            }
        }

        .fieldset.estimate {
            & > .legend,
            & > .legend + br {
                @extend .abs-no-display;
            }
        }
    }
    .actions-toolbar {
        & > .primary {
            button {
                @extend .abs-revert-secondary-color;
            }
        }
    }
}

//  Totals block
.cart-totals {
    @extend .abs-sidebar-totals;
    padding-top: $indent__s;
    background-color: $background-cart-yellow;

    .table-wrapper {
        margin-bottom: 0;
        overflow: inherit;
        .mark, .amount{
          font-size: 12px;
          font-weight: 100;
          font-family: 'q3sc';
        }
    }
    .totals{
      margin: 0;
    }
}

//  Products table
.cart.table-wrapper {
    background-color: $background-cart-yellow;
    .items {
        & > .item {
            position: relative;
        }
        font-family: 'q3sc';
        font-size: 12px;
    }

    .col {
        padding-top: $indent__base;

        &.qty {
            .input-text {
                @extend .abs-input-qty;
                margin-top: -5px;
            }

            .label {
                @extend .abs-visually-hidden;
            }
        }
    }

    .item {
        &-actions td {
            text-align: center;
            white-space: normal;
        }

        .col {
            &.item {
                display: block;
                min-height: 75px;
                padding: $indent__m 0 $indent__s 75px;
                position: relative;
                max-width: 330px;
            }
        }
    }

    .actions-toolbar {
        @extend .abs-add-clearfix;

        & > .action {
            @extend button;
            @include lib-link-as-button();
            margin-bottom: $indent__s;
            margin-right: $indent__s;
            color: #ca171d;
            font-size: 12px;
            text-decoration: underline;
            &:last-child {
                margin-right: 40px;
                float: right;
            }
        }
    }

    .action {
        &.help.map {
            @extend .abs-action-button-as-link;
            font-weight: $font-weight__regular;
        }
    }

    .product {
        &-item-photo {
            display: block;
            left: 0;
            max-width: 60px;
            padding: 0;
            position: absolute;
            top: 15px;
        }

        &-item-details {
            white-space: normal;
        }

        &-item-name {
            display: inline-block;
            font-weight: $font-weight__regular;
            margin-top: 0;
            a{
              font-weight: $font-weight__bold;
              line-height: 23px;
            }
        }
    }
    .control select{
      padding: 0;
      border: 1px solid #c6c6c6;
      border-radius: 5px;
      font-size: 12px;
    }
    .gift-registry-name-label {
        &:after {
            content: ':';
        }
    }

    //  Product options
    .item-options {
        @extend .abs-product-options-list;
        @extend .abs-add-clearfix;
        font-size: $font-size__s;
        margin-bottom: $indent__s;

        dt {
            float: left;
            margin: 0 0 5px;
        }

        dd {
            float: left;
            margin: 0 0 5px;
        }
    }

    .product-item-name + .item-options {
        margin-top: $indent__s;
        width: 100%;
        margin: 0;
        height: 90px;
        overflow: auto;
      //  padding-right: 32px;
    }

    .product-image-wrapper {
        height: 160px;
        padding: 0 !important;
    }

    .action.configure {
        display: inline-block;
        margin: 0 0 $indent__base;
    }
    .price-including-tax .price, .price-excluding-tax .price{
      font-size: 12px;
      font-weight: 100;
      line-height: 17px;
    }
    th:first-child {
    padding: 20px 25px 0 20px;
    }
}

.cart-container {
    .form-cart {
        @extend .abs-shopping-cart-items;
        .main.actions{
          background-color: $background-cart-yellow;
        }
    }

    .checkout-methods-items {
        @extend .abs-reset-list;
        text-align: center;
        padding-top: 45px;
        padding-bottom: 13.5px;
        background-color: $background-cart-yellow;
        border-bottom: 2px solid $border-checkout-bottom-orange;
        button.action.primary.checkout{
          float: none;
          text-align: center;
          span{
            display: none;
          }
        }
        button.action.primary.checkout:before{
          content: 'Proceed To Checkout';
          font-size: 16.5px;
          font-family: 'q3sc';
          position: relative;
          right: unset;
        }
        .action.primary {
            @extend .abs-button-l;
            width: 100%;
        }

        .item {
            & + .item {
                margin-top: $indent__base;
            }
        }
        .cart-shipping-info{

            p{
            color: #ca171d;
            padding: 4px;
            font-size: 12px;
            font-family: 'q3sc', sans-serif;
            line-height: 16px;
            text-align: left;
        }
       }
    }
}

//
//  Cross sell
//  ---------------------------------------------

.block.crosssell {
    margin-top: 70px;
}

.cart-tax-info,
.cart .cart-tax-info {
    & + .cart-tax-total {
        display: block;
    }
}

.cart.table-wrapper,
.order-items.table-wrapper {
    .col.price,
    .col.qty,
    .col.subtotal,
    .col.msrp {
        text-align: left;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .cart {
        &.table-wrapper {
            overflow: inherit;

            th{
                &.col {
                    &:not(.item) {
                        display: none;
                    }
                }
            }

            .col {
                &.qty,
                &.price,
                &.subtotal,
                &.msrp {
                    box-sizing: border-box;
                    display: block;
                    float: left;
                    text-align: center;
                    white-space: nowrap;
                    width: 33%;
                    &:before {
                        content: attr(data-th) ":";
                        display: block;
                        font-weight: $font-weight__bold;
                        padding-bottom: $indent__s;
                    }
                }

                &.msrp {
                    white-space: normal;
                }
                &.qty .field.qty{
                  .input-text{
                    text-align: left;
                    padding-left: 10px;
                  }

                }
            }

            .item {
                .col.item {
                    padding-bottom: 20px 8px 10px;
                }
            }
            .cart.item{
                border-bottom: 2px solid $border-checkout-bottom-orange;
            }
            .product-item-photo{
              padding-left: 10px;
            }
            .product-item-details{
              padding-left: 20px;
              text-align: left;
            }
            .control select{
              display: inherit;
            }
            .actions-toolbar{
              margin-top: 10px;
            }
            .actions-toolbar > .action:last-child {
              margin-right: 0;
              float: none;
            }
            .actions-toolbar-desktop{
              display: none;
            }
            .product-image-wrapper {
                .product-image-photo{
                  position: static;
                }
            }
        }
    }

    .cart-container {
        .form-cart {
            @extend .abs-shopping-cart-items-mobile;
            width: 100%;
        }
    }
}

.order-review-form {
    button.action.primary.checkout {
        width: 100%;

        &:before {
            background-image: none;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .cart-container {
        @extend .abs-add-clearfix-desktop;
        .form-cart {
            @extend .abs-shopping-cart-items-desktop;
            width: 70%;
        }

        .widget {
            float: left;
        }
    }

    .cart-summary {
        float: right;
        position: relative;
        width: 30%;

        .actions-toolbar {
            .column.main & {
                @extend .abs-reset-left-margin-desktop;
                & > .secondary {
                    float: none;
                }
            }
        }

        .block {
            .fieldset {
                .field {
                    @include lib-form-field-type-revert($_type: block);
                    margin: 0 0 $indent__s;
                }
            }
        }
    }

    .cart {
        &.table-wrapper {
            .items { // Google Chrome version 44.0.2403.107 m fix
                min-width: 100%;
                width: auto;
                .item-info{
                  border-bottom: 2px solid $border-checkout-bottom-orange;
                }
                .col {
                    &.qty,
                    &.price,
                    &.subtotal,
                    &.msrp {
                        padding: 8px !important;
                    }
            }
        }
            .item {
                .col {
                    &.item {
                        padding: 20px 8px $indent__s;
                    }
                }

                &-actions {
                    td {
                        text-align: right;
                    }
                }
            }

            .product {
                &-item-photo {
                    display: table-cell;
                    max-width: 100%;
                    padding-right: $indent__base;
                    position: static;
                    vertical-align: top;
                    width: 1%;
                }

                &-item-details {
                    display: table-cell;
                    vertical-align: top;
                    white-space: normal;
                    width: 50%;
                    padding-left: 0;
                    text-align: left;
                }
            }


            .actions-toolbar-desktop{
                @media(max-width:1032px){
                    width: 150%;
                }
              margin-top: -48px;
              width: 175%;
              text-align: left;
              margin-left: 107px !important;
              padding-bottom: 0;
            }
            .actions-toolbar-mobile{
              display: none;
            }
            .actions-toolbar > .action {
              padding-bottom: 0px;
              padding-top: 23px;
            }
        }
    }
}
.checkout-cart-index{
  .page-title-wrapper{
    text-align: left;
    font-family: 'q3';
    margin-bottom: 0;
    .base{
      font-size: 16px;
    }
  }
  #block-shipping {
      display: none;
  }
  .action-delete::before {
    content: none;
  }
}
