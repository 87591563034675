//
//  Variables
//  _____________________________________________

$checkout-sidebar__margin: $indent__base !default;
$checkout-sidebar__margin__xl: 46px !default;
$checkout-sidebar__columns: 4 !default;

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .opc-sidebar {
        margin: 0;
        //@include lib-layout-column(2, 2, $checkout-sidebar__columns);
        width: 30%;
        float: right;
    }
}
.modal-custom .action-close::before{
  color: inherit;
}
